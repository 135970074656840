import ChatTeardropDots from 'components/icons/ChatTeardropDots';
import ClockIcon from 'components/icons/ClockIcon';
import LocalIcon from 'components/icons/LocalIcon';
import PencilIcon from 'components/icons/PencilIcon';
import SignOutIcon from 'components/icons/SignOutIcon';
import Targeticon from 'components/icons/TargetIcon';
import TextIcon from 'components/icons/TextIcon';
import routes from 'constants/routes';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import User from 'models/User';
import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import './ProfileComponent.scss';

interface Props {
    history: any;
    user: User;
    logout: () => void;
}

const ProfileComponent: React.FC<Props> = (props) => {
    const { history, user, logout } = props;
    const [dropdown, setDropdown] = useState(false);

    return (
        <DashboardLayout logo>
            <div className="profile-confirmation overflow-container">
                <div className="profile-confirmation-heading-wrapper">
                    <div className="profile-confirmation-heading">
                        <h3>Profile details</h3>
                        <button
                            onClick={() => history.push(routes.EDIT_PROFILE, { data: 'profile' })}
                            className="profile-confirmation-button-edit"
                        >
                            <PencilIcon className="button-edit-icon" color="#C482E3" />
                            Edit
                        </button>
                    </div>
                    <OutsideClickHandler onOutsideClick={() => setDropdown(false)}>
                        <div className="header-dots" onClick={() => setDropdown(!dropdown)}>
                            {<img src={require('../../../assets/images/dots-three-vertical.png')} alt="dots" />}
                            {dropdown && (
                                <div className="header-dropdown">
                                    <div className="dropdown-item" onClick={logout}>
                                        <SignOutIcon height={20} width={20} />
                                        <p>Sign out</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </OutsideClickHandler>
                </div>
                <div className="profile-confirmation-user">
                    <img src={user.imgProfile || require('../../../assets/images/user-placeholder.jpg')} alt="user" />
                    <div className="profile-confirmation-user-wrapper">
                        <p className="profile-confirmation-user-name">
                            {user.firstName} {user.lastName}
                        </p>
                        <p className="profile-confirmation-user-number">{user.telephone}</p>
                    </div>
                </div>

                <div className="profile-confirmation-preferences">
                    <TextIcon className="profile-confirmation-preferences-icon" />
                    <p className="profile-confirmation-preferences-text">{user.about}</p>
                </div>
                <div className="profile-confirmation-preferences">
                    <ChatTeardropDots className="profile-confirmation-preferences-icon" />
                    <p className="profile-confirmation-preferences-text captalize">
                        {user.availability?.join(', ')}
                    </p>
                </div>
                <div className="profile-confirmation-preferences">
                    <ClockIcon className="profile-confirmation-preferences-icon" />
                    <p className="profile-confirmation-preferences-text">{user.messagesFrecuency}</p>
                </div>
                <div className="profile-confirmation-preferences">
                    <Targeticon className="profile-confirmation-preferences-icon" />
                    <p className="profile-confirmation-preferences-text">{user.weightGoal || 'Weightloss goal not set'}</p>
                </div>
                <div className="profile-confirmation-preferences">
                    <LocalIcon className="profile-confirmation-preferences-icon" />
                    <p className="profile-confirmation-preferences-text">{user.userSettings?.matchesByLocation ? 'Prioritize matches based on location' : "Don't prioritize matches based on location"}</p>
                </div>
                <div className="profile-confirmation-separator" />
                <div className="profile-confirmation-heading">
                    <h3>Interests</h3>
                    <button
                        onClick={() => history.push(routes.EDIT_PASSIONS, { data: 'profile' })}
                        className="profile-confirmation-button-edit"
                    >
                        <PencilIcon className="button-edit-icon" color="#C482E3" />
                        Edit
                    </button>
                </div>
                <div className="profile-confirmation-tag-container">
                    {user.passions?.map(({ idPassion, namePassion }: { idPassion: string; namePassion: string }) => (
                        <div className="profile-confirmation-tag" key={idPassion}>
                            {namePassion}
                        </div>
                    ))}
                </div>
                <div className="profile-confirmation-heading">
                    <h3>Obstacles</h3>
                    <button
                        onClick={() => history.push(routes.EDIT_OBSTACLES, { data: 'profile' })}
                        className="profile-confirmation-button-edit"
                    >
                        <PencilIcon className="button-edit-icon" color="#C482E3" />
                        Edit
                    </button>
                </div>
                <div className="profile-confirmation-tag-container">
                    {user.obstacles?.map(
                        ({ idObstacle, nameObstacle }: { idObstacle: string; nameObstacle: string }) => (
                            <div className="profile-confirmation-tag" key={idObstacle}>
                                {nameObstacle}
                            </div>
                        )
                    )}
                </div>
            </div>
        </DashboardLayout>
    );
};

export default ProfileComponent;
