import routes from 'constants/routes';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { patchUserService, uploadImageService, deleteUserService } from 'services/user';
import { patchUserSettingService } from 'services/user-settings';
import useUser from 'stores/user';
import EditProfileComponent from '../components/EditProfileComponent';

const messageFrequencyOptions: { value: string; label: string }[] = [
    { value: 'Few times a day', label: 'Few times a day' },
    { value: 'Once a day', label: 'Once a day' },
    { value: 'Few times a week', label: 'Few times a week' },
    { value: 'Once a week', label: 'Once a week' },
];
const availabilityOptions: { value: string; label: string }[] = [
    { value: 'Weekdays', label: 'Weekdays' },
    { value: 'Weekends', label: 'Weekends' },
    { value: 'Mornings', label: 'Mornings' },
    { value: 'Afternoons', label: 'Afternoons' },
    { value: 'Evenings', label: 'Evenings' },
    { value: 'Nights', label: 'Nights' },
];

const weightGoalOptions: { value: string; label: string }[] = [
    { value: '<50', label: 'Under 50lbs' },
    { value: '51-100', label: '51 to 100lbs' },
    { value: '101-150', label: '101 to 150lbs' },
    { value: '>151', label: '151+lbs' }
]


const EditProfile: React.FC<any> = (props) => {
    const history = useHistory<any>();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const { user, setUser, updateUser } = useUser();
    const [file, setFile] = useState<any>(null);
    const [updated, setUpdated] = useState(false);
    const [deleteAccountModal, setDeleteAccountModal] = useState(false);

    const handleFile = (file: any) => {
        if (!!file)
            setFile({ file, preview: URL.createObjectURL(file) })
    }

    const submit = async (values: any) => {
        const {
            firstName,
            lastName,
            about,
            messagesFrecuency,
            availability,
            weightGoal,
            matchesByLocation
        }: {
            firstName: string;
            lastName: string;
            about: string;
            messagesFrecuency: { label: string; value: string };
            availability: { label: string; value: string }[];
            weightGoal: { label: string; value: string };
            matchesByLocation: boolean;
        } = values;

        setLoading(true);

        let body: any = {
            userFeatures: {
                firstName,
                lastName,
                about,
                messagesFrecuency: messagesFrecuency.value,
                availability: availability.map((availability) => availability.value),
                weightGoal: weightGoal.value,
            },
        };

        try {
            if (file?.file) {
                const imageLink: string | any = await uploadImageService(file.file);
                body = { ...body, userFeatures: { ...body.userFeatures, imgProfile: imageLink } };
            }
            await patchUserService(body);
            user.idUser && await patchUserSettingService(user.idUser, { matchesByLocation })

            updateUser(body.userFeatures);
            updateUser({ userSettings: { ...user.userSettings, matchesByLocation } })
            setUpdated(true);
        } catch (error: any) {
            alert(error?.message);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const openDeleteAccountModal = () => setDeleteAccountModal(true);
    const closeDeleteAccountModal = () => setDeleteAccountModal(false);

    const logout = () => {
        setUser({});
        localStorage.clear();
    }

    const deleteAccount = async () => {
        try {
            await deleteUserService();
            logout();
        } catch (error) {
            console.log('Error deleting account', error);
        }
    }

    useEffect(() => {
        if (user.imgProfile) setFile((file: any) => ({ ...file, preview: user.imgProfile }));
    }, [user]);

    useEffect(() => {
        if (updated) {
            setUpdated(false);
            if (history.location.state?.data === 'profile') {
                return history.push(routes.PROFILE);
            }
            return history.push(routes.PROFILE_CONFIRMATION);
        }
    }, [updated, history]);

    return (
        <EditProfileComponent
            {...props}
            submit={submit}
            error={error}
            loading={loading}
            history={history}
            file={file}
            handleFile={handleFile}
            user={user}
            messageFrequencyOptions={messageFrequencyOptions}
            availabilityOptions={availabilityOptions}
            weightGoalOptions={weightGoalOptions}
            deleteAccount={deleteAccount}
            deleteAccountModal={deleteAccountModal}
            openDeleteAccountModal={openDeleteAccountModal}
            closeDeleteAccountModal={closeDeleteAccountModal}
        />
    );
};

export default EditProfile;
