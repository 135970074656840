import customFetch from "./customFetch";
import { endpoints } from "./endpoints";
import { ServerError } from "./errorHandling";
import { getUserToken } from "./utils";

export const getObstaclesService = async () => {
    const token = getUserToken()

    try {
        let response = await customFetch(endpoints.obstacles, {
            method: "GET",
            token
        });


        return response;
    } catch (err: any) {
        throw new ServerError(err);
    }
};