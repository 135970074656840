import React, { useRef } from 'react';
import ProfileCreationLayout from 'layouts/ProfileCreationLayout/ProfileCreationLayout';
import './EditProfileComponent.scss';
import routes from 'constants/routes';
import { Formik, Form } from "formik";
import * as yup from "yup";
import MaterialInput from 'components/elements/MaterialInput/materialInput';
import PencilIcon from 'components/icons/PencilIcon';
import Dropdown from 'components/elements/Selects/Dropdown/Dropdown'
import { Dialog } from '@material-ui/core';
import Button from 'components/elements/Button/Button';
import { MaterialSwitch } from 'components/elements/MaterialSwitch/MaterialSwitch';


const EditProfileComponent: React.FC<any> = (props) => {
    const { submit, history, user, file, handleFile, messageFrequencyOptions, availabilityOptions, weightGoalOptions,
        deleteAccount, deleteAccountModal, openDeleteAccountModal, closeDeleteAccountModal, loading
    } = props;

    let fileInputRef = useRef<any>(null);

    const handleClick = () => {
        fileInputRef.current.click();
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const fileUploaded = e.target.files[0];
            handleFile(fileUploaded);
        }
    };

    const formikRef = useRef<any>(null);
    const submitForm = () => {
        formikRef?.current?.submitForm();
    }

    if(loading) return <div className='loading'> <span className='text'>Loading...</span></div>

    return (
        <ProfileCreationLayout
            headerTitle="Edit profile"
            cancel={() => {
                if (history.location.state?.data === 'profile') return history.push(routes.PROFILE)
                history.push(routes.PROFILE_CONFIRMATION)
            }}
            save={submitForm}
        >
            <div className="edit-profile overflow-container">
                <Formik
                    innerRef={formikRef}
                    initialValues={{
                        firstName: user.firstName,
                        lastName: user.lastName,
                        about: user.about,
                        messagesFrecuency: { label: user.messagesFrecuency, value: user.messagesFrecuency },
                        availability: user.availability.map((availability: string) => ({ label: availability, value: availability })),
                        weightGoal: { label: user.weightGoal, value: user.weightGoal },
                        matchesByLocation: user.userSettings.matchesByLocation,
                    }}
                    validationSchema={yup.object().shape({
                        firstName: yup.string().required("First name is required"),
                        lastName: yup.string().required("Last name is required")
                    })}
                    onSubmit={submit}>
                    {(formik) => (
                        <Form>
                            <button className="edit-profile-image" type="button" onClick={handleClick}>
                                <img src={file?.preview || require('../../../assets/images/user-placeholder.jpg')}
                                    alt="profile" />
                                <div className='edit-profile-image-label'>
                                    <PencilIcon className="button-edit-icon" />
                                    <span>Edit</span>
                                </div>
                                <input ref={fileInputRef}
                                    type="file"
                                    multiple
                                    accept="image/*"
                                    onChange={handleChange}
                                    style={{ display: 'none' }}
                                />
                            </button>
                            <p className='edit-profile-label'>First name</p>
                            <MaterialInput
                                id="firstName"
                                formik={formik}
                                placeholder="First name"
                                label=""
                                variant='outlined'
                            />
                            <p className='edit-profile-label'>Last name</p>
                            <MaterialInput
                                id="lastName"
                                formik={formik}
                                placeholder="Last name"
                                label=""
                                variant='outlined'
                            />
                            <p className='edit-profile-label'>Description</p>
                            <MaterialInput
                                id="about"
                                formik={formik}
                                className="formControl-about"
                                placeholder="Description"
                                type="textarea"
                                label=""
                                maxLength={400}
                                variant='outlined'
                                caption={`${formik.values["about"]?.length ? formik.values["about"].length : 0}/400`}
                            />
                            <p className='edit-profile-label'>Messages frequency</p>
                            <Dropdown
                                id="messagesFrecuency"
                                defaultValue={messageFrequencyOptions.find(({ value }: { value: string }) => value === formik.values.messagesFrecuency.value)}
                                onChange={(option) => formik.setFieldValue('messagesFrecuency', { value: option.value, label: option.label })}
                                options={messageFrequencyOptions}
                            />
                            <p className='edit-profile-label'>Availability</p>
                            <Dropdown
                                id="availability"
                                defaultValue={user.availability.map((availability: string) => ({ value: availability, label: availability }))}
                                onChange={(option) => formik.setFieldValue('availability', option)}
                                options={availabilityOptions}
                                isMulti
                            />
                            <p className='edit-profile-label'>Weightloss goal</p>
                            <Dropdown
                                id="weightGoal"
                                defaultValue={weightGoalOptions.find(({ value }: { value: string }) => value === formik.values.weightGoal.value)}
                                onChange={(option) => formik.setFieldValue('weightGoal', { value: option.value, label: option.label })}
                                options={weightGoalOptions}
                            />
                            <div className='switch-container'>
                                <p className='edit-profile-label'>Prioritize matches based on location</p>
                                <MaterialSwitch
                                    checked={Boolean(formik.values.matchesByLocation)}
                                    defaultChecked={Boolean(formik.values.matchesByLocation)}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue('matchesByLocation', e.target.checked)}
                                />
                            </div>
                        </Form>)
                    }
                </Formik>
                {history.location.state?.data === 'profile' &&
                    <button className="delete-account" onClick={openDeleteAccountModal}>
                        <img src={require('assets/images/trash-icon.png')} alt="trash" />
                        <p className="body1">Delete my account</p>
                    </button>
                }
                {<Dialog className="modal" open={deleteAccountModal}>
                    <div className="match-modal">
                        <img src={require('assets/images/image-matches-blank.png')} alt="" />
                        <h2>Are you sure?</h2>
                        <p>If you delete your account you will lose all your data permanently.</p>
                        <div className="match-modal-buttons">
                            <Button onClick={closeDeleteAccountModal}>Cancel</Button>
                            <Button secondary onClick={deleteAccount}>
                                Yes, delete my account
                            </Button>
                        </div>
                    </div>
                </Dialog>}
            </div>
        </ProfileCreationLayout >);
}

export default EditProfileComponent;