import React, { useEffect, useState } from 'react';
import PhoneVerificationComponent from "../components/PhoneVerificationComponent";
import { useHistory, withRouter } from "react-router-dom";
import routes from 'constants/routes';
import { getPhoneCode, patchUserService } from 'services/user';
import useUser from 'stores/user';

const PhoneVerification: React.FC<any> = (props) => {
    const history = useHistory();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const { user, setUser } = useUser();

    useEffect(() => {
        if (user.telephone) {
            history.push(routes.FEED)
        }
    }, [user.telephone, history])

    const submit = async (values: { prefix: string, number: string }) => {
        const telephone = `+${values.prefix}${values.number.replaceAll(' ', '')}`
        setLoading(true);

        try {
            await patchUserService({
                userFeatures: {
                    telephone,
                },
            })
            await getPhoneCode()

            history.push({
                pathname: routes.SMS_CONFIRMATION,
                state: { number: `+${values.prefix}-${values.number.replaceAll(' ', '-')}` }
            });
        }
        catch (error: any) {
            setError(error.message);
        }
        finally {
            setLoading(false);

        }
    }

    const logout = () => {
        setUser({});
        localStorage.clear();
    }

    const goBack = () => {
        logout();
        history.push(routes.REGISTER)
    }

    return <PhoneVerificationComponent
        {...props}
        submit={submit}
        loading={loading}
        error={error}
        goBack={goBack}
    />;
}

export default withRouter(PhoneVerification);