import Header from 'components/elements/Header/Header';
import Navigation from 'components/Navigation/container/Navigation';
import React from 'react';
import './DashboardLayout.scss'

export interface Props {
    children: React.ReactNode;
    headerTitle?: string;
    logo?: boolean;
    goBack?: () => void;
    noPadding?: boolean;
    right?: any;
}

const DashboardLayout: React.FC<any> = (props) => {
    const { children, headerTitle, goBack, logo, right, logoWhite, className = "", noPadding } = props;

    return (
        <div className={`dashboard-layout ${className}`}>
            {(headerTitle || goBack) && <Header title={headerTitle} noBackgrounnd goBack={goBack} right={right} />}
            <div className={`dashboard-content${noPadding ? ' no-padding' : ''}`}>
                {logo &&
                    <div className="logo">
                        <img src={require('../../assets/images/logo.png')} alt="logo" />
                    </div>}
                {logoWhite &&
                    <div className="logo">
                        <img src={require('../../assets/images/logo_white.png')} alt="logo" />
                    </div>}
                {children}
            </div>
            <Navigation />
        </div>
    )
}

export default DashboardLayout;