import ChatTearDropIcon from 'components/icons/ChatTearDropIcon';
import CompassFilledIcon from 'components/icons/CompassFilledIcon';
import CompassIcon from 'components/icons/CompassIcon';
import ProfileFilledIcon from 'components/icons/ProfileFilledIcon';
import ProfileIcon from 'components/icons/ProfileIcon';
import ThumbsUpFilledIcon from 'components/icons/ThumbsUpFilledIcon';
import ThumbsUpIcon from 'components/icons/ThumbsUpIcon';
import routes from 'constants/routes';
import React from 'react';
import './NavigationComponent.scss';

interface Navigation {
    history?: any
}

const NavigationComponent: React.FC<Navigation> = ({ history }) => {
    const { location: { pathname } } = history

    const isActive = (route: string) => pathname === route

    return (
        <div className='navigation'>
            <button className={`navigation-item${isActive(routes.FEED) ? ' active' : ''}`} onClick={() => history.push(routes.FEED)}>
                {isActive(routes.FEED) ? <CompassFilledIcon /> : <CompassIcon />}
                <p className="navigation-item-name">
                    Feed
                </p>
            </button>
            <button className={`navigation-item${isActive(routes.MESSAGES) ? ' active' : ''}`} onClick={() => history.push(routes.MESSAGES)}>
                <ChatTearDropIcon color={isActive(routes.MESSAGES) ? '#00525c' : '#A1A1A5'} />
                <p className="navigation-item-name">
                    Messages
                </p>
            </button>
            <button className={`navigation-item${isActive(routes.PROFILE) ? ' active' : ''}`} onClick={() => history.push(routes.PROFILE)}>
                {isActive(routes.PROFILE) ? <ProfileFilledIcon /> : <ProfileIcon />}
                <p className="navigation-item-name">
                    Profile
                </p>
            </button>
            <button className={`navigation-item${isActive(routes.FEEDBACK) ? ' active' : ''}`} onClick={() => history.push(routes.FEEDBACK)}>
                {isActive(routes.FEEDBACK) ? <ThumbsUpFilledIcon /> : <ThumbsUpIcon />}
                <p className="navigation-item-name" onClick={() => history.push(routes.FEED)}>
                    Feedback
                </p>
            </button>
        </div >
    )
}

export default NavigationComponent;