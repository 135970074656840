import React from 'react';
import './ProgressBar.scss';

export interface Props {
    percentage: 0 | 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90 | 100
}

const ProgressBar: React.FC<Props> = ({ percentage }) => {
    return (
        <div className="progress-bar-container">
            <div className={`progress-bar progress-bar-${percentage}`} />
        </div>
    )
}

export default ProgressBar;