import { useEffect } from 'react';

// Fix for avoiding height issues with the browser top bar in mobile devices

const useCustomViewHeight = () => {

    const setVHCustomProperty = () => {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    useEffect(() => {
        setVHCustomProperty();
    }, [])

    useEffect(() => {
        // We listen to the resize event
        window.addEventListener('resize', setVHCustomProperty);

        return () => {
            window.removeEventListener('resize', setVHCustomProperty);
        }
    }, [])
}

export default useCustomViewHeight