import React, { useRef, useState } from "react";
import ProfileCreationLayout from "layouts/ProfileCreationLayout/ProfileCreationLayout";
import "./EditObstaclesComponent.scss";
import { Formik, Form } from "formik";
import routes from "constants/routes";
import MaterialCheckbox from "../../elements/Selects/MaterialCheckBox/MaterialCheckBox"
import { Obstacle } from "../containers/EditObstacles";

const MIN_SELECTED = 1;
const MAX_SELECTED = 7;

const EditObstaclesComponent: React.FC<any> = (props) => {
    const { submit, error, history, obstacles, user } = props;
    const [obstaclesSelected, setObstaclesSelected] = useState<Obstacle[]>(user.obstacles);
    const [selectionError, setSelectionError] = useState(false)


    const isObstacleSelected = (obstacle: Obstacle) => {
        if (obstaclesSelected.find(({ idObstacle }) => idObstacle === obstacle.idObstacle)) {
            return true;
        }
        return false;
    }

    const handleSelection = (obstacle: Obstacle, setValue: (name: string, value: Obstacle[]) => void) => {
        let newObstacles = [];
        if (isObstacleSelected(obstacle)) {
            newObstacles = obstaclesSelected.filter(({ idObstacle }) => idObstacle !== obstacle.idObstacle);
            setValue("obstacles", newObstacles);
            return setObstaclesSelected(newObstacles);
        }

        if (obstaclesSelected.length === MAX_SELECTED) return;

        newObstacles = [...obstaclesSelected, obstacle]
        setValue("obstacles", newObstacles);
        return setObstaclesSelected(newObstacles);
    }

    const formikRef = useRef<any>(null)
    const submitForm = () => formikRef?.current?.submitForm()

    const handleValidation = () => {
        if (obstaclesSelected.length < MIN_SELECTED) return setSelectionError(true)
        if (obstaclesSelected.length > MAX_SELECTED) return setSelectionError(true)
        return submitForm()
    }

    return (
        <ProfileCreationLayout
            headerTitle="Obstacles"
            cancel={() => {
                if (history.location.state?.data === 'profile') return history.push(routes.PROFILE)
                history.push(routes.PROFILE_CONFIRMATION)
            }}
            save={handleValidation}
        >
            <div className="edit-obstacles overflow-container">
                <Formik
                    innerRef={formikRef}
                    initialValues={{ obstacles: user.obstacles }}
                    onSubmit={submit}>
                    {(formik) => (
                        <Form>
                            {selectionError && <p className='error-message'>{`Choose at least ${MIN_SELECTED}, up to ${MAX_SELECTED}`}</p>}
                            <div className="create-profile-form-grid">
                                {obstacles.map((obstacle: Obstacle) => (
                                    <div className={`create-profile-form-card${isObstacleSelected(obstacle) ? ' selected' : ''}`}
                                        key={obstacle.idObstacle}
                                        onClick={() => handleSelection(obstacle, formik.setFieldValue)}>
                                        <p className="body1">{obstacle.nameObstacle}</p>
                                        <MaterialCheckbox value={isObstacleSelected(obstacle)} className="secondary" />
                                    </div>
                                ))}
                            </div>
                            {error
                                ? <div className="error-message-global">{error}</div>
                                : null
                            }
                        </Form>
                    )}
                </Formik>
            </div>
        </ProfileCreationLayout >);
}

export default EditObstaclesComponent;