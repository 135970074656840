// const BASE_URL = process.env.REACT_APP_API_URL || 'https://app.myturnip.com:444'; //PRO
const BASE_URL = process.env.REACT_APP_API_URL || 'https://api.dev.weightloss.pitagorinesgroup.com'; //DEV
// const BASE_URL = 'http://localhost:3001/';

export const endpoints = {
    users: BASE_URL + '/users',
    user: BASE_URL + '/user',
    userSettings: BASE_URL + '/user-settings',
    passions: BASE_URL + '/passions',
    obstacles: BASE_URL + '/obstacles',
    media: BASE_URL + '/uploadFilesMultiPart',
    singleMatches: BASE_URL + '/single-matches',
    matches: BASE_URL + '/matches',
    userMatched: BASE_URL + '/userMatched',
    chats: BASE_URL + '/chats',
    feedbacks: BASE_URL + '/feedbacks',
    globalUser: BASE_URL + '/global/user',
};
