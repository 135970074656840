import React, { useState } from "react";
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';

import Button from "components/elements/Button/Button";

import './FeedbackSubmitedComponent.scss';

const FeedbackSubmitedComponent: React.FC<any> = props => {
    const { redirect } = props

    return (
        <DashboardLayout logo>
            <div className="feedback-submited">
                <div className="feedback-submited-image">
                    <img className="feedback-submited-image__img" src={require('../../../assets/images/illustration-feedback.png')} alt="logo name" />
                </div>
                <div className="feedback-submited-text">
                    <h1>Thanks for your feedback!</h1>
                    <p>We will valorate it. Stay tuned to our news.</p>
                </div>
                <div className="feedback-submited-button">
                    <Button
                        type="button"
                        onClick={redirect}>
                        Continue using App
                    </Button>
                </div>
            </div>
        </DashboardLayout>
    );
}

export default FeedbackSubmitedComponent;
