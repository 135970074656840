import routes from 'constants/routes';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getObstaclesService } from 'services/obstacles';
import { patchUserService } from 'services/user';
import useUser from 'stores/user';
import CreateProfileStep4Component from '../components/CreateProfileStep4Component';
import * as Mixpanel from 'config/mixpanel';

export interface Obstacle {
    idObstacle: string;
    nameObstacle: string;
}

const CreateProfileStep4: React.FC<any> = () => {
    const history = useHistory<any>();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [obstacles, setObstacles] = useState<any[]>([]);
    const { user, updateUser } = useUser();
    const [updated, setUpdated] = useState(false);

    const getObstacles = async () => {
        try {
            const data = await getObstaclesService();
            setObstacles(data);
        } catch (error: any) {
            setError(error.message);
        }
    };

    useEffect(() => {
        getObstacles();
    }, []);

    const submit = async (values: { obstacles: Obstacle[] }) => {
        setLoading(true);

        const body: { userFeatures: { obstacles: string[] } } = {
            userFeatures: {
                obstacles: values.obstacles.map((value: Obstacle) => value.idObstacle),
            },
        };

        try {
            await patchUserService(body);
            updateUser(values);

            Mixpanel.actions.sendEvent(Mixpanel.EVENT_TYPES.ONBOARDING_TROUBLES, {
                'User ID': user.idUser,
                'Sign up date': undefined,
                'Enabled Push Notifications ': user.userSettings ? user.userSettings.allowNotifications : false,
                'Email address': user.email,
                'Phone Number': user.telephone,
                $name: `${user.firstName} ${user.lastName}`,
                'Prioritize Matches based on Location?': user.userSettings
                    ? user.userSettings.matchesByLocation
                    : false,
                Interests: user.passions,
                Troubles: values.obstacles,
                'Message Frequency Preference ': user.messagesFrecuency,
                'Message Timing Preference ': user.availability,
                'Weightloss Goal': user.weightGoal,
                '3 Important Things': user.about,
                '#Matches': undefined,
                '#Messages Sent': undefined,
                'First Login Date': undefined,
                'Last Login Date ': undefined,
            });

            setUpdated(true);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (updated) {
            setUpdated(false);
            return history.push(routes.CREATE_PROFILE_STEP_5);
        }
    }, [updated, history]);

    return (
        <CreateProfileStep4Component
            submit={submit}
            error={error}
            loading={loading}
            history={history}
            obstacles={obstacles}
            user={user}
        />
    );
};

export default CreateProfileStep4;
