import React, { useEffect, useRef, useState } from 'react';
import ProfileCreationLayout from 'layouts/ProfileCreationLayout/ProfileCreationLayout';
import './EditPassionsComponent.scss';
import routes from 'constants/routes';
import { Formik, Form } from "formik";
import { Passion } from '../containers/EditPassions';

const MIN_SELECTED = 3;
const MAX_SELECTED = 7;

const EditProfileComponent: React.FC<any> = (props) => {
    const { submit, history, user, passions } = props;

    const [passionsSelected, setPassionsSelected] = useState<Passion[]>(user.passions);
    const [selectionError, setSelectionError] = useState(false)
    const [showArrowDown, setShowArrowDown] = useState(true)

    const isPassionSelected = (passion: Passion) => {
        if (passionsSelected.find(({ idPassion }) => idPassion === passion.idPassion)) {
            return true
        }
        return false
    }

    const handleSelection = (passion: Passion, setValue: (name: string, value: Passion[]) => void) => {
        let newPassions = []
        if (isPassionSelected(passion)) {
            newPassions = passionsSelected.filter(({ idPassion }) => idPassion !== passion.idPassion);
            setValue('passions', newPassions)
            return setPassionsSelected(newPassions);
        }

        if (passionsSelected.length === MAX_SELECTED) return;

        newPassions = [...passionsSelected, passion]
        setValue('passions', newPassions)
        return setPassionsSelected(newPassions);
    }

    const formikRef = useRef<any>(null)
    const submitForm = () => formikRef?.current?.submitForm()


    const handleValidation = () => {
        if (passionsSelected.length < MIN_SELECTED) return setSelectionError(true)
        if (passionsSelected.length > MAX_SELECTED) return setSelectionError(true)
        return submitForm()
    }

    const scrollRef = useRef<any>({})
    useEffect(() => {
        window.addEventListener('scroll', trackScrolling);
        return () => window.removeEventListener('scroll', trackScrolling);
    }, []);

    const trackScrolling = () => {
        if (scrollRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
            if ((scrollTop + clientHeight + 50 >= scrollHeight) && showArrowDown) {
                setShowArrowDown(false)
            }
            else if ((scrollTop + clientHeight + 50 < scrollHeight) && !showArrowDown) setShowArrowDown(true)
        }
    };

    return (
        <ProfileCreationLayout
            headerTitle="Interests"
            cancel={() => {
                if (history.location.state?.data === 'profile')
                    return history.push(routes.PROFILE)
                history.push(routes.PROFILE_CONFIRMATION)
            }}
            save={handleValidation}
            arrowDown={showArrowDown}
        >
            <div className="edit-passions" onScroll={trackScrolling} ref={scrollRef}>
                <Formik
                    innerRef={formikRef}
                    initialValues={{ passions: user.passions }}
                    onSubmit={submit}>
                    {(formik) => (
                        <Form style={{ height: '100%' }}>
                            {selectionError && <p className='error-message'>{`Choose at least ${MIN_SELECTED}, up to ${MAX_SELECTED}`}</p>}
                            <div className="create-profile-form-grid">
                                {passions.map((passion: Passion) => (
                                    <div className="create-profile-form-card"
                                        key={passion.idPassion}
                                        onClick={() => handleSelection(passion, formik.setFieldValue)}>
                                        <img src={passion.imgPassion} alt="passion" className={`passion-image ${isPassionSelected(passion) ? 'selected' : ''}`} />
                                        <span>{passion.namePassion}</span>
                                        {isPassionSelected(passion) &&
                                            <div className="checkbox">
                                                <img src={require('../../../assets/images/check.png')} alt="check" />
                                            </div>}
                                    </div>
                                ))}
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </ProfileCreationLayout >);
}

export default EditProfileComponent;