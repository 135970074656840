import customFetch from "./customFetch";
import { endpoints } from "./endpoints";
import { ServerError } from "./errorHandling";
import { getUserToken } from "./utils";

export const getMatchesService = async () => {
    const token = getUserToken()

    try {
        let response = await customFetch(`${endpoints.matches}/me`, {
            method: "GET",
            token
        });

        return response;
    } catch (err: any) {
        throw new ServerError(err);
    }
};

export const getMatchesByIdService = async (id: string) => {
    const token = getUserToken()

    try {
        let response = await customFetch(`${endpoints.matches}/${id}`, {
            method: "GET",
            token
        });

        return response;
    } catch (err: any) {
        throw new ServerError(err);
    }
};

export const deleteMatchesByIdService = async (id: string) => {
    const token = getUserToken()

    try {
        let response = await customFetch(`${endpoints.matches}/me`, {
            bodyReq: {
                idUserRejected: id
            },
            method: "DELETE",
            token
        });

        return response;
    } catch (err: any) {
        throw new ServerError(err);
    }
};