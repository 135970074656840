import React from 'react';
import './PrivacyPolicyComponent.scss';
import Header from 'components/elements/Header/Header';

interface Props {
    goBack: () => void;
}

const PrivacyPolicyComponent: React.FC<Props> = ({ goBack }) => {
    return (
        <div className="privacy-policy">
            <Header goBack={goBack} title="Terms & Conditions and Privavy Policy" />
            <div className="privacy-policy-container">
                <p className="privacy-policy-heading">Terms and Conditions</p>
                <p className='privacy-policy-text'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                <p className="privacy-policy-heading">Privacy Policy</p>
                <p className='privacy-policy-text'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est s.  Amet minim mollit non deserunt ullamco est s Amet minim mollit non deserunt ullamco est s</p>
            </div>
        </div>
    )
}

export default PrivacyPolicyComponent;