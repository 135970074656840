import * as React from "react"

const ChatTearDropIcon = (props) => (
    <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M16.5 2.999A12.514 12.514 0 0 0 4 15.499v10.542a1.96 1.96 0 0 0 1.958 1.958H16.5a12.5 12.5 0 1 0 0-25Zm3.5 16h-7.5a1 1 0 1 1 0-2H20a1 1 0 0 1 0 2Zm0-4h-7.5a1 1 0 1 1 0-2H20a1 1 0 0 1 0 2Z"
            fill={props.white ? "#00525c" : props.color || '#FFFFFF'}
        />
    </svg>
)

export default ChatTearDropIcon;
