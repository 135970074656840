import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import history from './services/history';
// Material UI
import { materialTheme } from 'designSystem/materialTheme';
import { ThemeProvider } from '@material-ui/core';

// Router Components
import PublicRoute from 'components/elements/PublicRoute/PublicRoute';
import PrivateRoute from 'components/elements/PrivateRoute/PrivateRoute';

// Auth Routes
import Login from './components/Login/containers/Login';
import Register from './components/Register/containers/Register';
import Feedback from './components/Feedback/containers/Feedback';

// Dashboard (all users) Routes
import Home from './components/Home/containers/Home';

// Admin Routes
import CustomPage from './components/Admin/CustomPage/containers/CustomPage';
import routes from 'constants/routes';
import ForgotPassword from 'components/ForgotPassword/containers/ForgotPassword';
import PhoneVerification from 'components/PhoneVerification/containers/PhoneVerification';
import SmsConfirmation from 'components/SmsConfirmation/containers/SmsConfirmation';
import CommunityRulesComponent from 'components/CommunityRules/CommunityRulesComponent';
import WelcomePageComponent from 'components/Welcome/WelcomePageComponent';
import NewPassword from 'components/NewPassword/containers/NewPassword';
import CreateProfileStep1 from 'components/CreateProfileStep1/containers/CreateProfileStep1';
import CreateProfileStep2 from 'components/CreateProfileStep2/containers/CreateProfileStep2';
import CreateProfileStep3 from 'components/CreateProfileStep3/containers/CreateProfileStep3';
import CreateProfileStep4 from 'components/CreateProfileStep4/containers/CreateProfileStep4';
import CreateProfileStep5 from 'components/CreateProfileStep5/containers/CreateProfileStep5';
import CreateProfileStep6 from 'components/CreateProfileStep6/containers/CreateProfileStep6';
import CreateProfileStep7 from 'components/CreateProfileStep7/containers/CreateProfileStep7';
import CreateProfileStep8 from 'components/CreateProfileStep8/containers/CreateProfileStep8';
import CreateProfileStep9 from 'components/CreateProfileStep9/containers/CreateProfileStep9';
import ProfileConfirmation from 'components/ProfileConfirmation/containers/ProfileConfirmation';
import EditProfile from 'components/EditProfile/containers/EditProfile';
import EditPassions from 'components/EditPassions/containers/EditPassions';
import EditObstacles from 'components/EditObstacles/containers/EditObstacles';
import Profile from 'components/Profile/containers/Profile';
import Feed from 'components/Feed/containers/Feed';
import MatchProfile from 'components/MatchProfile/containers/MatchProfile';
import Messages from 'components/Messages/containers/Messages';
import Chat from 'components/Chat/containers/Chat';
import PrivacyPolicy from 'components/PrivacyPolicy/containers/PrivacyPolicy';
import useCustomViewHeight from 'hooks/useCustomViewHeight';

import useUser from 'stores/user';
import _ from 'lodash';
import * as Mixpanel from 'config/mixpanel';

function App() {
    // Fix for avoiding height issues with the browser top bar in mobile devices
    useCustomViewHeight();

    const { user } = useUser();
    const isLogged = !_.isEmpty(user);

    useEffect(() => {
        if (isLogged) {
            // console.log('user', user);
            Mixpanel.actions.sendEvent(Mixpanel.EVENT_TYPES.VISIT_APP, {
                'User ID': user.idUser,
                'Sign up date': undefined,
                'Enabled Push Notifications ': user.userSettings ? user.userSettings.allowNotifications : false,
                'Email address': user.email,
                'Phone Number': user.telephone,
                $name: `${user.firstName} ${user.lastName}`,
                'Prioritize Matches based on Location?': user.userSettings
                    ? user.userSettings.matchesByLocation
                    : false,
                Interests: user.passions,
                Troubles: user.obstacles,
                'Message Frequency Preference ': user.messagesFrecuency,
                'Message Timing Preference ': user.availability,
                'Weightloss Goal': user.weightGoal,
                '3 Important Things': user.about,
                '#Matches': undefined,
                '#Messages Sent': undefined,
                'First Login Date': undefined,
                'Last Login Date ': undefined,
            });
        } else {
            Mixpanel.actions.sendEvent(Mixpanel.EVENT_TYPES.VISIT_APP);
        }
    }, []);

    return (
        <ThemeProvider theme={materialTheme}>
            <Router history={history}>
                <div className="App">
                    <Switch>
                        <PublicRoute restricted component={Login} path="/" exact />
                        <PublicRoute restricted component={Login} path={routes.LOGIN} />
                        <PublicRoute restricted component={Register} path={routes.REGISTER} />
                        <PublicRoute component={PrivacyPolicy} path={routes.PRIVACY_POLICY} />
                        <PublicRoute restricted component={ForgotPassword} path={routes.FORGOT_PASSWORD} />
                        <PublicRoute restricted component={NewPassword} path={`${routes.CREATE_NEW_PASSWORD}/:token`} />

                        {/* Common private Routes */}
                        <PrivateRoute component={PhoneVerification} path={routes.PHONE_VERIFICATION} />
                        <PrivateRoute component={CommunityRulesComponent} path={routes.COMUNITY_RULES} />
                        <PrivateRoute component={WelcomePageComponent} path={routes.WELCOME} />
                        <PrivateRoute component={SmsConfirmation} path={routes.SMS_CONFIRMATION} />
                        <PrivateRoute component={CreateProfileStep1} path={routes.CREATE_PROFILE_STEP_1} />
                        <PrivateRoute component={CreateProfileStep2} path={routes.CREATE_PROFILE_STEP_2} />
                        <PrivateRoute component={CreateProfileStep3} path={routes.CREATE_PROFILE_STEP_3} />
                        <PrivateRoute component={CreateProfileStep4} path={routes.CREATE_PROFILE_STEP_4} />
                        <PrivateRoute component={CreateProfileStep5} path={routes.CREATE_PROFILE_STEP_5} />
                        <PrivateRoute component={CreateProfileStep6} path={routes.CREATE_PROFILE_STEP_6} />
                        <PrivateRoute component={CreateProfileStep7} path={routes.CREATE_PROFILE_STEP_7} />
                        <PrivateRoute component={CreateProfileStep8} path={routes.CREATE_PROFILE_STEP_8} />
                        <PrivateRoute component={CreateProfileStep9} path={routes.CREATE_PROFILE_STEP_9} />
                        <PrivateRoute component={ProfileConfirmation} path={routes.PROFILE_CONFIRMATION} />
                        <PrivateRoute component={EditProfile} path={routes.EDIT_PROFILE} />
                        <PrivateRoute component={EditPassions} path={routes.EDIT_PASSIONS} />
                        <PrivateRoute component={EditObstacles} path={routes.EDIT_OBSTACLES} />

                        <PrivateRoute component={Feed} path={routes.FEED} />
                        <PrivateRoute component={Chat} path={`${routes.MESSAGES}/:id`} />
                        <PrivateRoute component={Messages} path={routes.MESSAGES} />
                        <PrivateRoute component={Profile} path={routes.PROFILE} />
                        <PrivateRoute component={MatchProfile} path={routes.MATCH_PROFILE} />
                        <PrivateRoute component={Feedback} path={routes.FEEDBACK} />

                        <PrivateRoute role="Admin" component={CustomPage} path="/admin/custom-page" />
                    </Switch>
                </div>
            </Router>
        </ThemeProvider>
    );
}

export default App;
