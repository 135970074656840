import * as React from "react"

const CompassFilledIcon = (props) => (
    <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M16 3a13 13 0 1 0 13 13A13.015 13.015 0 0 0 16 3Zm6.544 7.765-3.512 7.768a1 1 0 0 1-.517.507l-7.425 3.182a1 1 0 0 1-1.313-1.313l3.182-7.424a1 1 0 0 1 .505-.517l7.755-3.525a1 1 0 0 1 1.325 1.322Z"
            fill={props.color || "#00525C"}
        />
    </svg>
)

export default CompassFilledIcon
