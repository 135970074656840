import React from 'react';
import { Message } from 'types/Message';
import moment from 'moment';
import './ChatContact.scss';

interface Props {
    name: string;
    lastMessage: Message;
    time: string;
    image: any;
    count: number;
    onClick: () => void;
}

const ChatContact: React.FC<Props> = ({ name, lastMessage, time, image, count, onClick }) => {
    return (
        <div className="chat-item" onClick={onClick}>
            <img
                className="chat-item-left"
                src={image ? image : require('../../../assets/images/user-placeholder.jpg')}
                alt="person"
            />
            <div className="chat-item-middle">
                <p className="chat-item-name">{name && name}</p>
                <p className="chat-item-message">{lastMessage?.message}</p>
            </div>
            <div className="chat-item-right">
                <p className="chat-item-time">{lastMessage ? moment(lastMessage?.createdAt).fromNow() : ''}</p>
                {count ? <p className="chat-item-count">{count}</p> : null}
            </div>
        </div>
    );
};

export default ChatContact;
