import * as React from "react"

const SunIcon = (props) => (
    <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M16 24.5a8.5 8.5 0 1 0 0-17 8.5 8.5 0 0 0 0 17ZM16 5.5a1 1 0 0 0 1-1v-1a1 1 0 0 0-2 0v1a1.001 1.001 0 0 0 1 1ZM7.161 8.575a1 1 0 1 0 1.415-1.414l-.708-.707a1 1 0 1 0-1.414 1.414l.707.707ZM4.5 15h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2ZM7.162 23.425l-.708.707a1 1 0 1 0 1.415 1.414l.707-.707a1 1 0 0 0-1.414-1.414ZM16 26.5a1.001 1.001 0 0 0-1 1v1a1 1 0 0 0 2 0v-1a1.001 1.001 0 0 0-1-1ZM24.84 23.425a1 1 0 0 0-1.415 1.414l.707.707a1 1 0 0 0 1.414-1.414l-.707-.707ZM28.5 15h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2ZM24.132 8.868a.997.997 0 0 0 .707-.293l.707-.707a1 1 0 1 0-1.414-1.414l-.707.707a1 1 0 0 0 .707 1.707Z"
            fill={props.white ? "#00525c" : '#FFFFFF'}
        />
    </svg>
)

export default SunIcon
