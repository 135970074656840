import React from 'react';
import { useHistory } from 'react-router-dom';
import NavigationComponent from '../components/NavigationComponent';

const Navigation: React.FC = () => {
    const history = useHistory()

    return <NavigationComponent history={history} />
}

export default Navigation;