import React from 'react';
import Button from 'components/elements/Button/Button';
import './CommunityRules.scss';
import { useHistory, withRouter } from 'react-router-dom';
import routes from 'constants/routes';
import Logo from 'components/elements/Logo/Logo';
import * as Mixpanel from 'config/mixpanel';
import useUser from 'stores/user';

const CommunityRulesComponent: React.FC<any> = () => {
    const history = useHistory<any>();
    const { user } = useUser();

    const acceptRulesHandling = () => {
        Mixpanel.actions.sendEvent(Mixpanel.EVENT_TYPES.VISIT_APP, {
            'User ID': user.idUser,
            'Sign up date': undefined,
            'Enabled Push Notifications ': user.userSettings ? user.userSettings.allowNotifications : false,
            'Email address': user.email,
            'Phone Number': user.telephone,
            $name: `${user.firstName} ${user.lastName}`,
            'Prioritize Matches based on Location?': user.userSettings ? user.userSettings.matchesByLocation : false,
            Interests: user.passions,
            Troubles: user.obstacles,
            'Message Frequency Preference ': user.messagesFrecuency,
            'Message Timing Preference ': user.availability,
            'Weightloss Goal': user.weightGoal,
            '3 Important Things': user.about,
            '#Matches': undefined,
            '#Messages Sent': undefined,
            'First Login Date': undefined,
            'Last Login Date ': undefined,
        });

        history.push(routes.WELCOME);
    };

    return (
        <div className="community-rules">
            <Logo />
            <h2 className="community-rules-title">Check our community rules to get started</h2>
            <div className="community-rules-container">
                {COMMUNITY_RULES.map((item, index) => (
                    <div className="community-rules-item" key={index}>
                        <div className="community-rules-icon">
                            <img src={item.icon} alt="icon" />
                        </div>
                        <div className="community-rules-text">
                            <p className="community-rules-text-title">{item.title}</p>
                            {item.description && <p className="community-rules-text-description">{item.description}</p>}
                        </div>
                    </div>
                ))}
            </div>
            <div className="community-rules-button-container">
                <Button className="community-rules-button" type="button" onClick={acceptRulesHandling}>
                    Accept & Continue
                </Button>
            </div>
        </div>
    );
};

export default withRouter(CommunityRulesComponent);

const COMMUNITY_RULES = [
    {
        icon: require('assets/images/feature-icon.png'),
        title: 'Be kind',
        description:
            'Please keep your feedback and comments constructive and supportive. Hateful comments will be removed along with your account.',
    },
    {
        icon: require('assets/images/feature-icon-2.png'),
        title: 'Privacy is important',
        description: 'Please be cautious in sharing personal information that would be a concern to you.',
    },
    {
        icon: require('assets/images/feature-icon-3.png'),
        title: 'Safety precaution',
        description:
            'As a safety precaution, if you do meet in public make sure someone knows when and where this is happening and preferably a public place.',
    },
    {
        icon: require('assets/images/feature-icon-4.png'),
        title: 'Participate',
        description: 'Participate and help us build a great community together.',
    },
    {
        icon: require('assets/images/feature-icon-5.png'),
        title: 'and... Have fun!',
    },
];
