import customFetch from "./customFetch";
import { endpoints } from "./endpoints";
import { ServerError } from "./errorHandling";
import { getUserId, getUserToken } from "./utils";

export const patchUserSettingService = async (
    id: string,
    body: {
        idUser?: string,
        allowNotifications?: boolean,
        matchesByLocation?: boolean,
        pushToken?: string
    }) => {

    const token = getUserToken()
    try {
        let response = await customFetch(`${endpoints.userSettings}/${id}`, {
            method: "PATCH",
            bodyReq: body,
            token
        });


        return response;
    } catch (err: any) {
        throw new ServerError(err);
    }
};

export const getUserSettingService = async () => {
    const id = getUserId();
    const token = getUserToken()

    try {
        let response = await customFetch(`${endpoints.userSettings}/${id}`, {
            method: "GET",
            token
        });

        return response;
    } catch (err: any) {
        throw new ServerError(err);
    }
};