import CalendarBlankIcon from 'components/icons/CalendarBlankIcon';
import CalendarWeekendIcon from 'components/icons/CalendarWeekendIcon';
import CloudMoonIcon from 'components/icons/CloudMoonIcon';
import MoonStarsIcon from 'components/icons/MoonStarsIcon';
import SunHorizonIcon from 'components/icons/SunHorizonIcon';
import SunIcon from 'components/icons/SunIcon';
import routes from 'constants/routes';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { patchUserService } from 'services/user';
import useUser from 'stores/user';
import * as Mixpanel from 'config/mixpanel';
import CreateProfileStep6Component from '../components/CreateProfileStep6Component';

export interface Availability {
    name: string;
    Icon: (white: { white: boolean }) => JSX.Element | null;
}

const AVAILABILITY_OPTIONS: Availability[] = [
    { name: 'Weekdays', Icon: (white) => <CalendarBlankIcon white={white} /> },
    { name: 'Weekends', Icon: (white) => <CalendarWeekendIcon white={white} /> },
    { name: 'Mornings', Icon: (white) => <SunIcon white={white} /> },
    { name: 'Afternoons', Icon: (white) => <SunHorizonIcon white={white} /> },
    { name: 'Evenings', Icon: (white) => <CloudMoonIcon white={white} /> },
    { name: 'Nights', Icon: (white) => <MoonStarsIcon white={white} /> },
];

const CreateProfileStep6: React.FC<any> = () => {
    const history = useHistory<any>();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const { user, updateUser } = useUser();
    const [updated, setUpdated] = useState(false);

    const submit = async (values: { availability: Availability[] }) => {
        setLoading(true);

        const body: { userFeatures: { availability: string[] } } = {
            userFeatures: {
                availability: values.availability.map((availability: Availability) => availability.name),
            },
        };

        try {
            await patchUserService(body);
            updateUser({ availability: body.userFeatures.availability });

            Mixpanel.actions.sendEvent(Mixpanel.EVENT_TYPES.ONBOARDING_MESSAGE_TIMING_PREFERENCE, {
                'User ID': user.idUser,
                'Sign up date': undefined,
                'Enabled Push Notifications ': user.userSettings ? user.userSettings.allowNotifications : false,
                'Email address': user.email,
                'Phone Number': user.telephone,
                $name: `${user.firstName} ${user.lastName}`,
                'Prioritize Matches based on Location?': user.userSettings
                    ? user.userSettings.matchesByLocation
                    : false,
                Interests: user.passions,
                Troubles: user.obstacles,
                'Message Frequency Preference ': user.messagesFrecuency,
                'Message Timing Preference ': values.availability,
                'Weightloss Goal': user.weightGoal,
                '3 Important Things': user.about,
                '#Matches': undefined,
                '#Messages Sent': undefined,
                'First Login Date': undefined,
                'Last Login Date ': undefined,
            });

            setUpdated(true);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (updated) {
            setUpdated(false);
            return history.push(routes.CREATE_PROFILE_STEP_7);
        }
    }, [updated, history]);

    return (
        <CreateProfileStep6Component
            submit={submit}
            error={error}
            loading={loading}
            history={history}
            availabilityOptions={AVAILABILITY_OPTIONS}
            user={user}
        />
    );
};

export default CreateProfileStep6;
