import React from 'react';
import ProfileCreationLayout from 'layouts/ProfileCreationLayout/ProfileCreationLayout';
import './ProfileConfirmationComponent.scss';
import Button from 'components/elements/Button/Button';
import routes from 'constants/routes';
import ChatTeardropDots from 'components/icons/ChatTeardropDots';
import TextIcon from 'components/icons/TextIcon';
import ClockIcon from 'components/icons/ClockIcon';
import PencilIcon from 'components/icons/PencilIcon';
import User from 'models/User';
import LocalIcon from 'components/icons/LocalIcon';
import * as Mixpanel from 'config/mixpanel';

interface Props {
    history: any;
    user: User;
}

const ProfileConfirmationComponent: React.FC<Props> = (props) => {
    const { history, user } = props;

    const confirmHandle = () => {
        history.push(routes.FEED);

        Mixpanel.actions.sendEvent(Mixpanel.EVENT_TYPES.ONBOARDING_COMPLETED, {
            'User ID': user.idUser,
            'Sign up date': undefined,
            'Enabled Push Notifications ': user.userSettings ? user.userSettings.allowNotifications : false,
            'Email address': user.email,
            'Phone Number': user.telephone,
            $name: `${user.firstName} ${user.lastName}`,
            'Prioritize Matches based on Location?': user.userSettings ? user.userSettings.matchesByLocation : false,
            Interests: user.passions,
            Troubles: user.obstacles,
            'Message Frequency Preference ': user.messagesFrecuency,
            'Message Timing Preference ': user.availability,
            'Weightloss Goal': user.weightGoal,
            '3 Important Things': user.about,
            '#Matches': undefined,
            '#Messages Sent': undefined,
            'First Login Date': undefined,
            'Last Login Date ': undefined,
        });
    };

    return (
        <ProfileCreationLayout
            headerTitle="Your profile"
            goBack={() => history.push(routes.CREATE_PROFILE_STEP_9)}
            submitButton={() => (
                <Button className="form-button" type="button" onClick={confirmHandle}>
                    Confirm
                </Button>
            )}
        >
            <div className="profile-confirmation overflow-container">
                <div className="profile-confirmation-heading">
                    <h3>Profile details</h3>
                    <button
                        onClick={() => history.push(routes.EDIT_PROFILE)}
                        className="profile-confirmation-button-edit"
                    >
                        <PencilIcon className="button-edit-icon" color="#C482E3" />
                        Edit
                    </button>
                </div>
                <div className="profile-confirmation-user">
                    <img src={user.imgProfile || require('../../../assets/images/user-placeholder.jpg')} alt="user" />
                    <div className="profile-confirmation-user-wrapper">
                        <p className="profile-confirmation-user-name">
                            {user.firstName} {user.lastName}
                        </p>
                        <p className="profile-confirmation-user-number">{user.telephone}</p>
                    </div>
                </div>

                <div className="profile-confirmation-preferences">
                    <TextIcon className="profile-confirmation-preferences-icon" />
                    <p className="profile-confirmation-preferences-text">{user.about}</p>
                </div>
                <div className="profile-confirmation-preferences">
                    <ChatTeardropDots className="profile-confirmation-preferences-icon" />
                    <p className="profile-confirmation-preferences-text">{user.messagesFrecuency}</p>
                </div>
                <div className="profile-confirmation-preferences">
                    <ClockIcon className="profile-confirmation-preferences-icon" />
                    <p className="profile-confirmation-preferences-text">
                        {[user.availability?.map((availability) => availability + ' ')]}
                    </p>
                </div>

                <div className="profile-confirmation-preferences">
                    <LocalIcon className="profile-confirmation-preferences-icon" />
                    <p className="profile-confirmation-preferences-text">
                        {user.userSettings?.matchesByLocation
                            ? 'Prioritize matches based on location'
                            : "Don't prioritize matches based on location"}
                    </p>
                </div>
                <div className="profile-confirmation-separator" />
                <div className="profile-confirmation-heading">
                    <h3>Interests</h3>
                    <button
                        onClick={() => history.push(routes.EDIT_PASSIONS)}
                        className="profile-confirmation-button-edit"
                    >
                        <PencilIcon className="button-edit-icon" color="#C482E3" />
                        Edit
                    </button>
                </div>
                <div className="profile-confirmation-tag-container">
                    {user.passions?.map(({ idPassion, namePassion }: { idPassion: string; namePassion: string }) => (
                        <div className="profile-confirmation-tag" key={idPassion}>
                            {namePassion}
                        </div>
                    ))}
                </div>
                <div className="profile-confirmation-heading">
                    <h3>Obstacles</h3>
                    <button
                        onClick={() => history.push(routes.EDIT_OBSTACLES)}
                        className="profile-confirmation-button-edit"
                    >
                        <PencilIcon className="button-edit-icon" color="#C482E3" />
                        Edit
                    </button>
                </div>
                <div className="profile-confirmation-tag-container">
                    {user.obstacles?.map(
                        ({ idObstacle, nameObstacle }: { idObstacle: string; nameObstacle: string }) => (
                            <div className="profile-confirmation-tag" key={idObstacle}>
                                {nameObstacle}
                            </div>
                        )
                    )}
                </div>
            </div>
        </ProfileCreationLayout>
    );
};

export default ProfileConfirmationComponent;
