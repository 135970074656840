import React, { useState } from "react";
import "./Input.scss";

const InputComponent = (props) => {
    const { type, label, id, className, formik, placeholder, rightIcon, rightIconFunc, labelInside, maxLength, onInput, prefixText = '', charCount } = props;
    let formikProps = {};
    const [focus, setFocus] = useState(false);


    if (formik) {
        formikProps = {
            value: formik.values[id],
            onBlur: () => formik.setFieldTouched(id),
            onChange: e => formik.setFieldValue(id, e.target.value),
            error: formik.touched[id] && formik.errors[id],
        };
    }

    return (
        <div className={`input-wrapper ${formikProps.error ? "error" : ""}`}>
            {label && <label htmlFor={id}>{label}</label>}
            <div className={`input-body ${focus ? 'focus' : ''} ${labelInside && "padding-top"}`} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}>
                {labelInside && <span className="label-inside">
                    {labelInside}
                </span>}
                <div className="input-body-wrapper">
                    {
                        (type === 'text' || type === 'password' || type === 'number') &&
                        <input
                            type={type}
                            id={id}
                            name={id}
                            className={`${className} ${formikProps.error ? "error" : ""} ${labelInside && "input-label-inside"}`}
                            onBlur={formikProps.onBlur}
                            onChange={formikProps.onChange}
                            value={prefixText ? `${prefixText}${formikProps.value}` : formikProps.value}
                            placeholder={placeholder}
                            maxLength={maxLength}
                            onInput={onInput}
                            autoComplete="on"
                        />
                    }
                    {
                        (charCount >= 0 && !maxLength) &&
                        <span className="text-count">{charCount}</span>
                    }
                    {
                        (charCount >= 0 && maxLength) &&
                        <span className="text-count">{charCount}/{maxLength}</span>
                    }
                    {rightIcon && <div className="input-icon right" onClick={rightIconFunc}>
                        <img src={rightIcon} alt="right-icon" />
                    </div>}
                </div>
            </div>
            {
                formikProps.error &&
                <span className="error-message">{formikProps.error}</span>
            }
        </div >
    );
};

export default InputComponent;
