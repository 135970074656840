import routes from 'constants/routes';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getPassionsService } from 'services/passions';
import { patchUserService } from 'services/user';
import useUser from 'stores/user';
import EditPassionsComponent from "../components/EditPassionsComponent"

export interface Passion {
    idPassion: string,
    imgPassion: string,
    namePassion: string
}

const EditPassions: React.FC<any> = (props) => {
    const history = useHistory<any>();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const { user, updateUser } = useUser()
    const [passions, setPassions] = useState<any[]>([]);
    const [updated, setUpdated] = useState(false)

    const getPassions = async () => {
        try {
            const data = await getPassionsService();
            setPassions(data);
        } catch (error: any) {
            setError(error.message);
        }
    }

    useEffect(() => {
        getPassions();
    }, []);

    const submit = async (values: any) => {

        setLoading(true);

        const body = {
            userFeatures: {
                passions: values.passions.map((value: Passion) => value.idPassion)
            }
        }

        try {
            await patchUserService(body)
            updateUser(values)
            setUpdated(true)
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (updated) {
            setUpdated(false)
            if (history.location.state?.data === 'profile') {
                return history.push(routes.PROFILE);
            }
            return history.push(routes.PROFILE_CONFIRMATION);
        }
    }, [updated, history])

    return <EditPassionsComponent
        {...props}
        submit={submit}
        error={error}
        loading={loading}
        history={history}
        user={user}
        passions={passions}
    />
}

export default EditPassions;