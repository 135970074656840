import routes from 'constants/routes';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { patchUserService } from 'services/user';
import useUser from 'stores/user';
import * as Mixpanel from 'config/mixpanel';

import CreateProfileStep7Component from '../components/CreateProfileStep7Component';

export interface Goal {
    name: string;
    subtitle: string;
}

const GOAL_OPTIONS: Goal[] = [
    { name: '<50', subtitle: 'Under 50lbs' },
    { name: '51-100', subtitle: '51 to 100lbs' },
    { name: '101-150', subtitle: '101 to 150lbs' },
    { name: '>151', subtitle: '151+lbs' },
];

const CreateProfileStep7: React.FC<any> = () => {
    const history = useHistory<any>();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const { user, updateUser } = useUser();
    const [updated, setUpdated] = useState(false);

    const submit = async (values: { goal: Goal }) => {
        setLoading(true);

        const body: { userFeatures: { weightGoal: string } } = {
            userFeatures: {
                weightGoal: values.goal.name,
            },
        };

        try {
            await patchUserService(body);
            updateUser({ weightGoal: values.goal.name });

            Mixpanel.actions.sendEvent(Mixpanel.EVENT_TYPES.ONBOARDING_WEIGHTLOSS_GOAL, {
                'User ID': user.idUser,
                'Sign up date': undefined,
                'Enabled Push Notifications ': user.userSettings ? user.userSettings.allowNotifications : false,
                'Email address': user.email,
                'Phone Number': user.telephone,
                $name: `${user.firstName} ${user.lastName}`,
                'Prioritize Matches based on Location?': user.userSettings
                    ? user.userSettings.matchesByLocation
                    : false,
                Interests: user.passions,
                Troubles: user.obstacles,
                'Message Frequency Preference ': user.messagesFrecuency,
                'Message Timing Preference ': user.availability,
                'Weightloss Goal': values.goal.name,
                '3 Important Things': user.about,
                '#Matches': undefined,
                '#Messages Sent': undefined,
                'First Login Date': undefined,
                'Last Login Date ': undefined,
            });

            setUpdated(true);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (updated) {
            setUpdated(false);
            return history.push(routes.CREATE_PROFILE_STEP_8);
        }
    }, [updated, history]);

    return (
        <CreateProfileStep7Component
            submit={submit}
            error={error}
            loading={loading}
            history={history}
            goalOptions={GOAL_OPTIONS}
            user={user}
        />
    );
};

export default CreateProfileStep7;
