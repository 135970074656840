import React, { useEffect } from 'react';
import ChatContact from 'components/elements/ChatContact/ChatContact';
import routes from 'constants/routes';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import './MessagesComponent.scss';
import User from 'models/User';
import ChatContactSkeleton from 'components/elements/ChatContact/ChatContactSkeleton';

interface Props {
    chats: any;
    user: User;
    loading: boolean;
}

const MessagesComponent: React.FC<Props> = ({ chats, user, loading }) => {
    const history = useHistory();
    console.log('chats', chats);
    console.log('loading', loading);

    return (
        <DashboardLayout logo>
            <div className="messages">
                <h3>Messages</h3>

                <div className="chats">
                    {loading ? (
                        _.times(3, () => <ChatContactSkeleton />)
                    ) : _.isEmpty(chats) ? (
                        <div className="empty-messages">
                            <img src={require('assets/images/messages.png')} />
                            <h3>This is where your matches will pop up!</h3>
                            <p>
                                Right now, you don't have any matches but when you do, they'll pop up here. Then you
                                just have to click on a person and pop them a note to connect.
                            </p>
                        </div>
                    ) : (
                        chats?.map((chat: any) => {
                            const receiverId = Object.keys(chat.participants).filter((id) => id !== user.idUser)[0];
                            const receiver = chat.participants[receiverId];

                            return (
                                <ChatContact
                                    key={chat.firebaseId}
                                    name={receiver.firstName}
                                    time={chat.createdAt}
                                    image={receiver.imgProfile}
                                    count={chat.unseenMessages}
                                    lastMessage={chat.lastMessage}
                                    onClick={() =>
                                        history.push(`${routes.MESSAGES}/${chat.firebaseId}`, { matchedUserId: receiverId })
                                    }
                                />
                            );
                        })
                    )}
                </div>
            </div>
        </DashboardLayout>
    );
};

export default MessagesComponent;
