import React, { useState } from 'react';
import SmsConfirmationComponent from "../components/SmsConfirmationComponent";
import { useHistory, withRouter } from "react-router-dom";
import routes from "constants/routes";
import { getPhoneCode, postVerifyPhoneService } from 'services/user';
import useUser from 'stores/user';

const SmsConfirmation: React.FC<any> = (props) => {
    const history = useHistory<any>();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const { user, setUser } = useUser();

    const submit = async (values: any) => {
        const { number1, number2, number3, number4 } = values
        const code = number1 + number2 + number3 + number4

        setLoading(true);

        try {
            await postVerifyPhoneService(code)
            setUser({ ...user, telephone: history.location.state.number })
            history.push(routes.COMUNITY_RULES);
        }
        catch (error: any) {
            setError(error.message);
        }
        finally {
            setLoading(false)
        }
    }

    const resendCode = async () => await getPhoneCode()



    return <SmsConfirmationComponent
        {...props}
        submit={submit}
        loading={loading}
        error={error}
        formattedPhoneNumber={123}
        resendCode={resendCode}
    />;
}

export default withRouter(SmsConfirmation);