import React, { useState } from 'react';
import './NewPasswordComponent.scss'
import Header from 'components/elements/Header/Header';
import AuthLayout from 'layouts/AuthLayout/AuthLayout';
import { Formik, Form } from "formik";
import * as yup from "yup";
import Button from "components/elements/Button/Button";
import Input from "components/elements/Input/Input";
import checkIfSubmitButtonisDisabled from 'utils/checkIfSubmitButtonisDisabled';

const NewPasswordComponent: React.FC<any> = (props) => {
    const { submit, error, loading } = props;
    const [showPass, setShowPass] = useState(false)

    return (
        <div className="new-password-component">
            <Header title="Forgot password" />
            <AuthLayout title="Enter new password"
                subtitle="Enter your new password"
                icon={require('../../../assets/images/sms-icon.png')}
            >
                <div className="new-password-form">
                    <Formik
                        initialValues={{ password: "" }}
                        validationSchema={yup.object().shape({
                            password: yup.string().required("New password required").min(8)
                        })}
                        onSubmit={submit}>
                        {(formik) => (
                            <Form>
                                <Input
                                    id="password"
                                    formik={formik}
                                    type={showPass ? "text" : "password"}
                                    className="formControl"
                                    placeholder="Password"
                                    rightIcon={showPass ? require('../../../assets/images/eye.png') : require('../../../assets/images/eye-closed.png')}
                                    rightIconFunc={() => setShowPass(!showPass)}
                                />
                                {error
                                    ? <div className="error-message-global">{error}</div>
                                    : null
                                }
                                <Button
                                    className="new-password-button"
                                    loading={loading}
                                    type="button"
                                    disabled={checkIfSubmitButtonisDisabled(formik, ['password'])}
                                    onClick={formik.handleSubmit}>
                                    Save
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </AuthLayout>
        </div>
    )
}

export default NewPasswordComponent