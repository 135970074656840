import React from 'react';
import Header from 'components/elements/Header/Header';
import './ProfileCreationLayout.scss';
import ProgressBar from 'components/elements/ProgressBar/ProgressBar';
import { BsFillArrowDownCircleFill } from 'react-icons/bs';
import Logo from 'components/elements/Logo/Logo';

export interface Props {
    children: React.ReactNode;
    percentage?: 0 | 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90 | 100;
    title?: string;
    subtitle?: string;
    headerTitle?: string;
    arrowDown?: boolean;
    goBack?: () => void;
    skip?: () => void;
    cancel?: () => void;
    save?: () => void;
    submitButton?: () => React.ReactNode;
}

const ProfileCreationLayout: React.FC<Props> = (props) => {
    const { children, percentage, headerTitle, title, subtitle, arrowDown, goBack, skip, cancel, save, submitButton } =
        props;

    return (
        <div className="create-profile-layout">
            <Logo />
            <Header
                title={headerTitle || 'Creating profile'}
                noBackgrounnd
                goBack={goBack}
                skipButton={skip}
                cancel={cancel}
                save={save}
            />
            {percentage && <ProgressBar percentage={percentage} />}
            {(title || subtitle) && (
                <div className="create-profile-heading">
                    <h3>{title}</h3>
                    {subtitle && <p>{subtitle}</p>}
                </div>
            )}
            {children}
            {submitButton && <div className="submit-container">{submitButton()}</div>}
            {arrowDown && (
                <div className={`arrow-down ${submitButton && 'with-button'}`}>
                    <BsFillArrowDownCircleFill size={40} />
                </div>
            )}
        </div>
    );
};

export default ProfileCreationLayout;
