import * as React from "react"

const SignOutIcon = (props) => (
    <svg
        width={props.width || 20}
        height={props.height || 20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M13.594 6.719 16.874 10l-3.28 3.281M8.125 10h8.748M8.125 16.875H3.75a.625.625 0 0 1-.625-.625V3.75a.625.625 0 0 1 .625-.625h4.375"
            stroke="#00525C"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default SignOutIcon
