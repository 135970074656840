import React, { useState } from "react";
import ProfileCreationLayout from "layouts/ProfileCreationLayout/ProfileCreationLayout";
import "./CreateProfileStep6Component.scss";
import Button from "components/elements/Button/Button";
import { Formik, Form } from "formik";
import routes from "constants/routes";
import MaterialCheckbox from "../../elements/Selects/MaterialCheckBox/MaterialCheckBox"
import { Availability } from "../containers/CreateProfileStep6";
import useFormikSubmitOutside from "hooks/useFormikSubmitOutside";

const CreateProfileStep6Component: React.FC<any> = (props) => {
    const { submit, error, loading, history, availabilityOptions, user } = props;
    const { formikRef, submitForm } = useFormikSubmitOutside();
    const [optionsSelected, setOptionsSelected] = useState<Availability[]>(
        availabilityOptions.filter((option: Availability) => user.availability?.find((availability: string) => option.name === availability))
    );

    const isOptionSelected = (availability: Availability) => !!optionsSelected?.find((option: Availability) => availability.name === option.name)

    const handleSelection = (availability: Availability, setValue: (name: string, values: Availability[]) => void) => {
        if (!isOptionSelected(availability)) {
            const newOptionsSelected = [...optionsSelected, availability];
            setValue('availability', newOptionsSelected);
            return setOptionsSelected(newOptionsSelected);
        }
        const newOptionsSelected = optionsSelected.filter((option: Availability) => option.name !== availability.name)
        setValue('availability', newOptionsSelected);
        return setOptionsSelected(newOptionsSelected);
    }

    return (
        <ProfileCreationLayout percentage={60}
            title="When will you most likely want to message with your partner?"
            goBack={() => history.push(routes.CREATE_PROFILE_STEP_5)}
            submitButton={() =>
                <Button
                    loading={loading}
                    type="button"
                    disabled={!optionsSelected.length}
                    onClick={submitForm}>
                    Continue
                </Button>
            }
        >
            <div className="create-profile-form-step-6 overflow-container">
                <Formik
                    innerRef={formikRef}
                    initialValues={{ availability: optionsSelected }}
                    onSubmit={submit}>
                    {(formik) => (
                        <Form>
                            <div className="create-profile-form-grid">
                                {availabilityOptions.map((availability: Availability) => (
                                    <div className={`create-profile-form-card${isOptionSelected(availability) ? ' selected' : ''}`}
                                        key={availability.name}
                                        onClick={() => handleSelection(availability, formik.setFieldValue)}
                                    >
                                        <div className="card-icon">
                                            <availability.Icon white={!isOptionSelected(availability)} />
                                        </div>
                                        <p className="body1">{availability.name}</p>
                                        {isOptionSelected(availability) && <MaterialCheckbox value={true} className="checkbox secondary" />}
                                    </div>
                                ))}
                            </div>
                            {error
                                ? <div className="error-message-global">{error}</div>
                                : null
                            }
                        </Form>
                    )}
                </Formik>
            </div>
        </ProfileCreationLayout >);
}

export default CreateProfileStep6Component;