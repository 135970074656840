import * as React from "react"

const GalleryIcon = (props) => (
    <svg
        width={61}
        height={61}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect width={61} height={61} rx={20} fill="#F4F4F5" />
        <path
            d="M36 26.5a1.5 1.5 0 1 1-3 .001 1.5 1.5 0 0 1 3-.001Zm8-5.5v18a2.002 2.002 0 0 1-2 2H20a2.002 2.002 0 0 1-2-2V21a2.002 2.002 0 0 1 2-2h22a2.002 2.002 0 0 1 2 2Zm-1.999 13.587L42 21H20v11.586L24.586 28a2.002 2.002 0 0 1 2.828 0L33 33.586 35.586 31a2.002 2.002 0 0 1 2.828 0l3.587 3.587Z"
            fill="#00525C"
        />
    </svg>
)

export default GalleryIcon