import React, { useEffect, useState } from 'react';
import LoginComponent from '../components/LoginComponent';
import { loginService } from 'services/user';
import useUser from 'stores/user';
import { useHistory } from 'react-router-dom';

const Login: React.FC<any> = (props) => {
    const history = useHistory<any>();
    const [error, setError] = useState(false);
    const [userBloqued, setUserBloqued] = useState(false);
    const [loading, setLoading] = useState(false);
    const { updateUser } = useUser();

    const submit = async (values: { email: string; password: string }) => {
        setLoading(true);

        const data = {
            email: values.email,
            password: values.password,
        };

        try {
            const response = await loginService(data);
            updateUser(response);
            setLoading(false);
        } catch (error: any) {
            setUserBloqued(error?.message?.includes('Sorry, your user is blocked'));
            setError(true);
            setLoading(false);
        }
    };

    return (
        <LoginComponent
            {...props}
            submit={submit}
            loading={loading}
            error={error}
            history={history}
            userBloqued={userBloqued}
            setUserBloqued={setUserBloqued}
        />
    );
};

export default Login;
