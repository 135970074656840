import React from 'react';
import { createStyles, Switch, SwitchClassKey, SwitchProps, Theme, withStyles } from "@material-ui/core";
import './MaterialSwitch.scss';

interface Props extends SwitchProps {
    classes: Styles,
    disabled: boolean,
    value: string,
}
interface Styles extends Partial<Record<SwitchClassKey, string>> {
    focusVisible?: string;
}

export const MaterialSwitch:any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 42,
            height: 26,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 1,
            '&$checked': {
                transform: 'translateX(16px)',
                color: theme.palette.common.white,
                '& + $track': {
                    backgroundColor: '#6FCF97',
                    opacity: 1,
                    border: 'none',
                },
            },
            '&$focusVisible $thumb': {
                color: '#6FCF97',
                border: '6px solid #fff',
            },
        },
        thumb: {
            width: 24,
            height: 24,
        },
        track: {
            borderRadius: 26 / 2,
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.grey[50],
            opacity: 1,
            transition: theme.transitions.create(['background-color', 'border']),
        },
        checked: {},
        focusVisible: {},
    }),
)(({ classes, disabled, ...props }: Props) => {
    return (
        <div className="switch-material-wrapper">
            <Switch
                focusVisibleClassName={classes.focusVisible}
                disableRipple
                disabled={disabled}
                classes={{
                    root: classes.root,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked,
                }}
                {...props}
            />
        </div>
    );
});