import * as React from "react"

const CloudMoonIcon = (props) => (
    <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M19.5 9a9.52 9.52 0 0 0-1.544.127 8.998 8.998 0 0 0-6.934-7.899 1 1 0 0 0-1.199 1.199 7.008 7.008 0 0 1-8.396 8.396A1 1 0 0 0 .228 12.02a9.024 9.024 0 0 0 4.037 5.633A6.496 6.496 0 0 0 9.5 28h10a9.5 9.5 0 0 0 0-19ZM2.671 12.994a9.01 9.01 0 0 0 9.323-9.328 6.957 6.957 0 0 1 3.998 6.006 9.552 9.552 0 0 0-5.362 5.426 6.463 6.463 0 0 0-4.883 1.1 7.032 7.032 0 0 1-3.076-3.204Z"
            fill={props.white ? "#00525c" : '#FFFFFF'}
        />
    </svg>
)

export default CloudMoonIcon
