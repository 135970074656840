import * as React from "react"

const PlusCircleIcon = (props) => (
    <svg
        width={props.width || 32}
        height={props.height || 32}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M6.808 25.192a13 13 0 1 0 0-18.385 13.015 13.015 0 0 0 0 18.385Zm6.364-13.435L16 14.586l2.828-2.829a1 1 0 0 1 1.415 1.414L17.414 16l2.829 2.828a1 1 0 1 1-1.415 1.414L16 17.414l-2.828 2.828a1 1 0 0 1-1.415-1.414L14.586 16l-2.829-2.829a1 1 0 1 1 1.415-1.414Z"
            fill="#00525C"
        />
    </svg>
)

export default PlusCircleIcon
