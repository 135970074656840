import * as React from "react"

const CalendarBlankIcon = (props) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25.999 4h-3V3a1 1 0 1 0-2 0v1h-10V3a1 1 0 1 0-2 0v1h-3a2.002 2.002 0 0 0-2 2v20a2.002 2.002 0 0 0 2 2h20a2.002 2.002 0 0 0 2-2V6a2.002 2.002 0 0 0-2-2Zm0 6h-20V6h3v1a1 1 0 0 0 2 0V6h10v1a1 1 0 0 0 2 0V6h3v4Z"
      fill={props.white ? "#00525c" : '#FFFFFF'}
    />
    <path
      d="M18.39 13.824c.243 0 .432.07.566.213a.76.76 0 0 1 .201.543c0 .229-.063.453-.189.674l-3.686 6.522a.86.86 0 0 1-.343.366.826.826 0 0 1-.425.118.912.912 0 0 1-.615-.236.786.786 0 0 1-.271-.603.93.93 0 0 1 .13-.472l3.225-5.648H13.71c-.575 0-.862-.244-.862-.733 0-.496.287-.744.862-.744h4.68Z"
      fill={props.white ? "#FFFFFF" : '#00525c'}
    />
  </svg>
)

export default CalendarBlankIcon