import mixpanel from 'mixpanel-browser';

mixpanel.init('2aad73e2a031d99c4445d3b2ef08b8c6');

let env_check = true;

export const EVENT_TYPES = {
    VISIT_APP: 'Visit App',
    SIGN_UP_START: 'Sign up start',
    USER_FINISHES_CREATING_AN_ACCOUNT: 'User finishes creating an account',
    ONBOARDING_START: 'Onboarding Start',
    ONBOARDING_ACCEPT_COMMUNITY_RULES: 'Onboarding: Accept Community Rules',
    ONBOARDING_NAME: 'Onboarding: Name',
    ONBOARDING_PRIORITIZE_LOCATION: 'Onboarding: Prioritize Location',
    ONBOARDING_INTERESTS: 'Onboarding: Interests',
    ONBOARDING_TROUBLES: 'Onboarding: Troubles',
    ONBOARDING_MESSAGE_FREQUENCY_PREFERENCE: 'Onboarding: Message Frequency Preference',
    ONBOARDING_MESSAGE_TIMING_PREFERENCE: 'Onboarding: Message Timing Preference',
    ONBOARDING_WEIGHTLOSS_GOAL: 'Onboarding: Weightloss Goal',
    ONBOARDING_3_IMPORTANT_THINGS: 'Onboarding: 3 Important Things',
    ONBOARDING_PROFILE_IMAGE: 'Onboarding: Profile Image',
    ONBOARDING_COMPLETED: 'Onboarding Completed',
    USER_SWIPED_RIGHT: 'User Swiped Right',
    USER_SWIPED_LEFT: 'User Swiped Left',
    USER_MATCHED: 'User Matched',
    USER_SENT_MESSAGE: 'User Sent Message',
    USER_RECEIVED_MESSAGE: 'User Received Message',
};

export const actions = {
    sendEvent: (event, data) => {
        if (env_check) {
            mixpanel.track(event, { ...data, platform: 'web' });
        }
    },
    pageView: (screen, data) => {
        if (env_check) {
            console.log('send page view');
            mixpanel.track('PAGE_VIEW', { screen, ...data, platform: 'web' });
        }
    },
};
