
export class ServerError extends Error {
    constructor(error: any) {
        super(error.message)

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ServerError)
        }

        this.name = 'Server error'
        switch (error.message) {
            case "Internal Server Error":
                this.message = "Internal server error. Please try again later"
                break;
            case "No account associated with the provided email address.":
                this.message = "There is no account associated to this email."
                break;

            default:
                this.message = error.message;
                break;
        }

    }
}