import * as React from "react"

const CalendarCheckIcon = (props) => (
  <svg
    width={24}
    height={26}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.999 2h-3V1a1 1 0 1 0-2 0v1h-10V1a1 1 0 1 0-2 0v1h-3a2.002 2.002 0 0 0-2 2v20a2.002 2.002 0 0 0 2 2h20a2.002 2.002 0 0 0 2-2V4a2.002 2.002 0 0 0-2-2Zm-4.814 12.727-5.833 5.5a1 1 0 0 1-1.374-.002l-3.167-3a.999.999 0 1 1 1.376-1.452l2.48 2.35 5.146-4.851a1.003 1.003 0 0 1 1.414.041 1.001 1.001 0 0 1-.042 1.414ZM22 8h-20V4h3v1a1 1 0 0 0 2 0V4h10v1a1 1 0 0 0 2 0V4h3v4Z"
      fill={props.white ? "#00525c" : '#FFFFFF'}
    />
  </svg>
)

export default CalendarCheckIcon;
