import React, { useEffect, useState } from 'react';
import FeedbackComponent from '../components/FeedbackComponent';
import FeedbackSubmitedComponent from '../components/FeedbackSubmitedComponent';
import { withRouter } from 'react-router-dom';
import routes from 'constants/routes';
import { useHistory } from 'react-router-dom';

import { feedbackService, putFeedbackService, getUserFeedback } from 'services/feedback';
import { getUserToken } from 'services/utils';
import { getUserIdService } from 'services/user';
import useUser from 'stores/user';

const Feedback: React.FC<any> = (props) => {
    const { setUser } = useUser();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submited, setSubmited] = useState(false);

    const history = useHistory();

    const submit = async (values: { rate: string; comment: string }) => {
        setLoading(true);

        const data = {
            opinion: values.rate,
            suggestions: values.comment,
        };

        try {
            let feedbackSubmited = await getUserFeedback();

            if (feedbackSubmited === 404) {
                await feedbackService(data);
            } else {
                await putFeedbackService(data);
            }

            setSubmited(true);
        } catch (error: any) {
            setError(error.message);
            console.log(error.statusCode);
        } finally {
            setLoading(false);
        }
    };

    const redirect = () => {
        history.push(routes.FEED);
    };

    const verifyIfUserIsBloqued = async () => {
        const token = getUserToken();
        const resp = await getUserIdService(token);

        if (resp.user.isBlocked) {
            setUser({});
            localStorage.clear();
            return true;
        }

        return false;
    };

    useEffect(() => {
        verifyIfUserIsBloqued();
    }, []);

    if (!submited) {
        return <FeedbackComponent {...props} submit={submit} loading={loading} error={error} />;
    } else {
        return <FeedbackSubmitedComponent {...props} redirect={redirect} />;
    }
};

export default withRouter(Feedback);
