import * as React from "react"

const CalendarWeekendIcon = (props) => (
    <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M25.999 4h-3V3a1 1 0 1 0-2 0v1h-10V3a1 1 0 1 0-2 0v1h-3a2.002 2.002 0 0 0-2 2v20a2.002 2.002 0 0 0 2 2h20a2.002 2.002 0 0 0 2-2V6a2.002 2.002 0 0 0-2-2Zm0 6h-20V6h3v1a1 1 0 0 0 2 0V6h10v1a1 1 0 0 0 2 0V6h3v4Z"
            fill={props.white ? "#00525c" : '#FFFFFF'}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.23 13.538c0-.68.552-1.23 1.231-1.23h4.923a1.23 1.23 0 1 1 0 2.461h-4.923a1.23 1.23 0 0 1-1.23-1.23ZM17.23 18.462c0-.68.552-1.231 1.231-1.231h4.923a1.23 1.23 0 1 1 0 2.461h-4.923a1.23 1.23 0 0 1-1.23-1.23ZM17.23 23.385c0-.68.552-1.231 1.231-1.231h4.923a1.23 1.23 0 1 1 0 2.461h-4.923a1.23 1.23 0 0 1-1.23-1.23Z"
            fill={props.white ? "#FFFFFF" : "#00525c"}
        />
    </svg>
)

export default CalendarWeekendIcon