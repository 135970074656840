import React, { useState } from "react";
import ProfileCreationLayout from "layouts/ProfileCreationLayout/ProfileCreationLayout";
import "./CreateProfileStep5Component.scss";
import Button from "components/elements/Button/Button";
import { Formik, Form } from "formik";
import routes from "constants/routes";
import MaterialCheckbox from "../../elements/Selects/MaterialCheckBox/MaterialCheckBox"
import { Frequency } from "../containers/CreateProfileStep5";
import useFormikSubmitOutside from "hooks/useFormikSubmitOutside";

const CreateProfileStep5Component: React.FC<any> = (props) => {
    const { submit, error, loading, history, frequencyOptions, user } = props;
    const [optionSelected, setOptionSelected] = useState<Frequency>(
        frequencyOptions.find((option: Frequency) => option.name === user.messagesFrecuency)
    );
    const { formikRef, submitForm } = useFormikSubmitOutside();

    const isOptionSelected = (frequency: Frequency) => frequency?.name === optionSelected?.name;

    const handleSelection = (frequency: Frequency, setValue: (name: string, values: Frequency) => void) => {
        if (!isOptionSelected(frequency)) {
            setOptionSelected(frequency);
            setValue('frequency', frequency)
        }
    }

    return (
        <ProfileCreationLayout percentage={50}
            title="How often do you think you want to message with your partner?"
            subtitle="You can change this later"
            goBack={() => history.push(routes.CREATE_PROFILE_STEP_4)}
            submitButton={() =>
                <Button
                    loading={loading}
                    type="button"
                    disabled={optionSelected?.name === ''}
                    onClick={submitForm}>
                    Continue
                </Button>}
        >
            <div className="create-profile-form-step-5 overflow-container">
                <Formik
                    innerRef={formikRef}
                    initialValues={{ frequency: optionSelected }}
                    onSubmit={submit}>
                    {(formik) => (
                        <Form>
                            <div className="create-profile-form-grid">
                                {frequencyOptions.map((frequency: Frequency) => (
                                    <div className={`create-profile-form-card${isOptionSelected(frequency) ? ' selected' : ''}`}
                                        key={frequency.name}
                                        onClick={() => handleSelection(frequency, formik.setFieldValue)}
                                    >
                                        <div className="card-icon">
                                            <frequency.Icon white={!isOptionSelected(frequency)} />
                                        </div>
                                        <p className="body1">{frequency.name}</p>
                                        {isOptionSelected(frequency) && <MaterialCheckbox value={true} className="checkbox secondary" />}
                                    </div>
                                ))}
                            </div>
                            {error
                                ? <div className="error-message-global">{error}</div>
                                : null
                            }
                        </Form>
                    )}
                </Formik>
            </div>
        </ProfileCreationLayout >);
}

export default CreateProfileStep5Component;