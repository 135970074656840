import * as React from "react"

const CompassIcon
  = (props) => (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 28c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12Z"
        stroke={props.color || "#A1A1A5"}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="m18.12 18.121 3.512-7.767-7.755 3.525-3.182 7.424 7.425-3.182Z"
        stroke={props.color || "#A1A1A5"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )

export default CompassIcon

