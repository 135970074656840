import * as React from "react"

const PencilIcon = (props) => (
    <svg
        width={13}
        height={13}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M4.565 10.272H2.283a.38.38 0 0 1-.38-.38V7.765a.38.38 0 0 1 .11-.269L7.72 1.791a.38.38 0 0 1 .538 0l2.125 2.125a.38.38 0 0 1 0 .538l-5.818 5.818ZM6.468 3.043 9.13 5.706"
            stroke={props.color || "#fff"}
            strokeWidth={1.304}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default PencilIcon
