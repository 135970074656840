import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

if (!firebase.apps.length)
    firebase.initializeApp({
        apiKey: "AIzaSyD5up1V_t9TFSYLigDH9vnSGz7bnbnPBFI",
        authDomain: "turnip-7ad0e.firebaseapp.com",
        projectId: "turnip-7ad0e",
        storageBucket: "turnip-7ad0e.appspot.com",
        messagingSenderId: "509270638720",
        appId: "1:509270638720:web:861b84f9f71f9c5864a665",
        databaseURL: 'https://turnip-7ad0e.firebaseio.com',
    });

export const firebaseApp = firebase;
export const auth = firebase.auth();
export const db = firebaseApp.firestore();
