import customFetch from "./customFetch";
import { endpoints } from "./endpoints";
import { ServerError } from "./errorHandling";
import { getUserToken } from "./utils";

export const getSingleMatchesService = async () => {
    const token = getUserToken()

    try {
        let response = await customFetch(`${endpoints.singleMatches}/me`, {
            method: "GET",
            token
        });

        return response;
    } catch (err: any) {
        throw new ServerError(err);
    }
};

export const getSingleMatchesRelatedService = async () => {
    const token = getUserToken()

    try {
        let response = await customFetch(`${endpoints.singleMatches}/related`, {
            method: "GET",
            token
        });

        return response;
    } catch (err: any) {
        throw new ServerError(err);
    }
};


export const postSingleMatchesService = async ({ idUserMatched }: {
    idUserMatched: string
}) => {
    const token = getUserToken()
    try {
        let response = await customFetch(`${endpoints.singleMatches}/me`, {
            method: "POST",
            bodyReq: {
                idUserMatched
            },
            token
        });


        return response;
    } catch (err: any) {
        throw new ServerError(err);
    }
};

export const deleteSingleMatchesService = async (id: string) => {
    const token = getUserToken()
    try {
        let response = await customFetch(`${endpoints.singleMatches}/reject `, {
            method: "POST",
            bodyReq: {
                idRejectedUser: id
            },
            token
        });


        return response;
    } catch (err: any) {
        throw new ServerError(err);
    }
};
