import React from 'react';
import { useHistory } from 'react-router-dom';
import MatchProfileComponent from '../components/MatchProfileComponent';

const MatchProfile: React.FC = () => {
    const history: any = useHistory()

    return <MatchProfileComponent history={history} info={history.location.state.user} />
}

export default MatchProfile;