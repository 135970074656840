import React from 'react';
import { Message } from 'types/Message';
import moment from 'moment';
import './ChatContact.scss';

interface Props {
}

const ChatContactSkeleton: React.FC<Props> = (props) => {
    return (
        <div className="chat-item skeleton" >
            <div
                className="chat-item-left"
            />
            <div className="chat-item-middle">
                <div className="chat-item-name"/>
                <div className="chat-item-message"/>
            </div>
            <div className="chat-item-right">
                <div className="chat-item-time"/>
            </div>
        </div>
    );
};

export default ChatContactSkeleton;
