import React, { useEffect, useRef, useState } from 'react';
import ProfileCreationLayout from 'layouts/ProfileCreationLayout/ProfileCreationLayout';
import './CreateProfileStep3Component.scss';
import Button from 'components/elements/Button/Button';
import { Formik, Form } from "formik";
import routes from 'constants/routes';
import useFormikSubmitOutside from 'hooks/useFormikSubmitOutside';

export interface Passion {
    idPassion: string,
    imgPassion: string,
    namePassion: string
}

const MIN_SELECTED = 3;
const MAX_SELECTED = 7;

const CreateProfileStep3Component: React.FC<any> = (props) => {
    const { submit, error, loading, history, passions, user } = props;
    const [passionsSelected, setPassionsSelected] = useState<any[]>(user.passions);
    const { formikRef, submitForm } = useFormikSubmitOutside();
    const [showArrowDown, setShowArrowDown] = useState(true)

    const isPassionSelected = (passion: Passion) => {
        if (passionsSelected.find(({ idPassion }) => idPassion === passion.idPassion)) {
            return true
        }
        return false
    }

    const handleSelection = (passion: Passion, setValue: (name: string, value: Passion[]) => void) => {
        let newPassions = []
        if (isPassionSelected(passion)) {
            newPassions = passionsSelected.filter(({ idPassion }) => idPassion !== passion.idPassion);
            setValue('passions', newPassions)
            return setPassionsSelected(newPassions);
        }

        if (passionsSelected.length === MAX_SELECTED) return;

        newPassions = [...passionsSelected, passion]
        setValue('passions', newPassions)
        return setPassionsSelected(newPassions);
    }

    const scrollRef = useRef<any>({})
    useEffect(() => {
        window.addEventListener('scroll', trackScrolling);
        return () => window.removeEventListener('scroll', trackScrolling);
    }, []);

    const trackScrolling = () => {
        if (scrollRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
            if ((scrollTop + clientHeight + 50 >= scrollHeight) && showArrowDown) {
                setShowArrowDown(false)
            }
            else if ((scrollTop + clientHeight + 50 < scrollHeight) && !showArrowDown) setShowArrowDown(true)
        }
    };

    return (
        <ProfileCreationLayout
            percentage={30}
            title="What are you interests?"
            subtitle={`Choose at least ${MIN_SELECTED}, up to ${MAX_SELECTED}`}
            goBack={() => history.push(routes.CREATE_PROFILE_STEP_2)}
            submitButton={() =>
                <Button
                    loading={loading}
                    type="button"
                    disabled={passionsSelected.length < MIN_SELECTED}
                    onClick={submitForm}>
                    Continue
                </Button>}
            arrowDown={showArrowDown}
        >
            <div className="create-profile-form-step-3 overflow-container" onScroll={trackScrolling} ref={scrollRef}>
                <Formik
                    innerRef={formikRef}
                    initialValues={{ passions: user.passions }}
                    onSubmit={submit}>
                    {(formik) => (
                        <Form>
                            <div className="create-profile-form-grid">
                                {passions.map((passion: Passion) => (
                                    <div className="create-profile-form-card"
                                        key={passion.idPassion}
                                        onClick={() => handleSelection(passion, formik.setFieldValue)}>
                                        <img src={passion.imgPassion} alt="passion" className={`passion-image ${isPassionSelected(passion) ? 'selected' : ''}`} />
                                        <span>{passion.namePassion}</span>
                                        {isPassionSelected(passion) &&
                                            <div className="checkbox">
                                                <img src={require('../../../assets/images/check.png')} alt="check" />
                                            </div>}
                                    </div>
                                ))}
                            </div>
                            {error
                                ? <div className="error-message-global">{error}</div>
                                : null
                            }

                        </Form>
                    )}
                </Formik>
            </div>
        </ProfileCreationLayout >);
}

export default CreateProfileStep3Component;