import Button from 'components/elements/Button/Button';
import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Welcome.scss';
import { useHistory, withRouter } from 'react-router-dom';
import routes from 'constants/routes';
import Logo from 'components/elements/Logo/Logo';
import useUser from 'stores/user';
import * as Mixpanel from 'config/mixpanel';

const WelcomePageComponent: React.FC<any> = () => {
    const history = useHistory<any>();
    const { user } = useUser();

    const getStartedHandler = () => {
        history.push(routes.CREATE_PROFILE_STEP_1);

        Mixpanel.actions.sendEvent(Mixpanel.EVENT_TYPES.ONBOARDING_START, {
            'User ID': user.idUser,
            'Sign up date': undefined,
            'Enabled Push Notifications ': user.userSettings ? user.userSettings.allowNotifications : false,
            'Email address': user.email,
            'Phone Number': user.telephone,
            $name: `${user.firstName} ${user.lastName}`,
            'Prioritize Matches based on Location?': user.userSettings ? user.userSettings.matchesByLocation : false,
            Interests: user.passions,
            Troubles: user.obstacles,
            'Message Frequency Preference ': user.messagesFrecuency,
            'Message Timing Preference ': user.availability,
            'Weightloss Goal': user.weightGoal,
            '3 Important Things': user.about,
            '#Matches': undefined,
            '#Messages Sent': undefined,
            'First Login Date': undefined,
            'Last Login Date ': undefined,
        });
    };

    return (
        <div className="welcome-page">
            <Logo />
            <h2 className="welcome-page-title">Welcome! Let’s complete your profile</h2>
            <p className="welcome-page-subtitle">
                You'll be creating a profile for the app. This information is so you can best find people you can
                connect with. All this information is editable after you create the profile.
            </p>
            <img
                className="welcome-page-image"
                src={require('../../assets/images/illustration-welcome.png')}
                alt="illustraion-welcome"
            />

            <Button className="welcome-page-button" type="button" onClick={getStartedHandler}>
                Get started
            </Button>
        </div>
    );
};

export default withRouter(WelcomePageComponent);
