import React from 'react'
import './Logo.scss'

const Logo: React.FC = () => {
    return (
        <div className="logo-wrapper">
            <img src={require('assets/images/logo.png')} alt="logo" />
        </div>
    )
}

export default Logo;