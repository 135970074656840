import * as React from "react"

const Targeticon = (props) => (
    <svg
        width={22}
        height={22}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m10 10 7.5-7.5M15.303 4.697a7.493 7.493 0 1 0 1.466 2.07"
            stroke={props.color || '#c6c6c8'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.651 7.348a3.75 3.75 0 1 0 1.093 2.438"
            stroke={props.color || '#c6c6c8'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default Targeticon

