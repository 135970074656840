import routes from 'constants/routes';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    deleteSingleMatchesService,
    getSingleMatchesRelatedService,
    postSingleMatchesService,
} from 'services/single-matches';
import { getUserIdService } from 'services/user';
import { getUserToken } from 'services/utils';
import useUser from 'stores/user';
import FeedComponent from '../components/FeedComponent';
import * as Mixpanel from 'config/mixpanel';

const Feed: React.FC = () => {
    const history: any = useHistory();
    const [singleMatches, setSingleMatches] = useState([]);
    const [loading, setLoading] = useState(true);
    const { user, setUser } = useUser();
    const [openMatchModal, setOpenMatchModal] = useState(false);
    const [nextSingleMatch, setNextSingleMatch] = useState(0);
    const [matched, setMatched] = useState<{ chatId: string | null; matchedUserId: string | null }>({
        chatId: null,
        matchedUserId: null,
    });

    const getSingleMatches = async () => {
        try {
            const data = await getSingleMatchesRelatedService();
            setSingleMatches(data);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const postSingleMatch = async (idUserMatched: string) => {
        if (user.idUser) {
            const isBloqued = await verifyIfUserIsBloqued();
            if (isBloqued) return;

            try {
                const data = await postSingleMatchesService({ idUserMatched });
                if (data.doubleMatch) {
                    setMatched({ ...matched, chatId: data.chatId, matchedUserId: idUserMatched });
                    return setOpenMatchModal(true);
                }
                setNextSingleMatch((nextSingleMatch: number) => nextSingleMatch + 1);

                Mixpanel.actions.sendEvent(Mixpanel.EVENT_TYPES.USER_MATCHED, {
                    'User ID': user.idUser,
                    'Sign up date': undefined,
                    'Enabled Push Notifications ': user.userSettings ? user.userSettings.allowNotifications : false,
                    'Email address': user.email,
                    'Phone Number': user.telephone,
                    $name: `${user.firstName} ${user.lastName}`,
                    'Prioritize Matches based on Location?': user.userSettings
                        ? user.userSettings.matchesByLocation
                        : false,
                    Interests: user.passions,
                    Troubles: user.obstacles,
                    'Message Frequency Preference ': user.messagesFrecuency,
                    'Message Timing Preference ': user.availability,
                    'Weightloss Goal': user.weightGoal,
                    '3 Important Things': user.about,
                    '#Matches': undefined,
                    '#Messages Sent': undefined,
                    'First Login Date': undefined,
                    'Last Login Date ': undefined,
                });
            } catch (error) {
                console.log(error);
            }
        }
    };

    const onDislike = async (idUser: string) => {
        const isBloqued = await verifyIfUserIsBloqued();
        if (isBloqued) return;
        try {
            await deleteSingleMatchesService(idUser);
            setNextSingleMatch((nextSingleMatch: number) => nextSingleMatch + 1);
        } catch (error) {
            console.log(error);
        }
    };

    const onKeepViewing = async () => {
        const isBloqued = await verifyIfUserIsBloqued();
        if (isBloqued) return;
        setOpenMatchModal(false);
        setNextSingleMatch((nextSingleMatch: number) => nextSingleMatch + 1);
    };

    const onMessage = async () => {
        const isBloqued = await verifyIfUserIsBloqued();
        if (isBloqued) return;
        setOpenMatchModal(false);
        setNextSingleMatch((nextSingleMatch: number) => nextSingleMatch + 1);
        history.push(`${routes.MESSAGES}/${matched.chatId}`, { matchedUserId: matched.matchedUserId });
    };

    const verifyIfUserIsBloqued = async () => {
        const token = getUserToken();
        const resp = await getUserIdService(token);

        if (resp.user.isBlocked) {
            setUser({});
            localStorage.clear();
            return true;
        }

        return false;
    };

    useEffect(() => {
        getSingleMatches();
    }, []);

    useEffect(() => {
        const next = history.location.state?.nextSingleMatch;
        if (!!next) return setNextSingleMatch(next);
        setNextSingleMatch(0);
    }, []);

    return (
        <FeedComponent
            singleMatches={singleMatches}
            onLike={postSingleMatch}
            onDislike={onDislike}
            onKeepViewing={onKeepViewing}
            openMatchModal={openMatchModal}
            nextSingleMatch={nextSingleMatch}
            onMessage={onMessage}
            history={history}
            loading={loading}
        />
    );
};

export default Feed;
