import React from 'react';
import './InputCode.scss';

const InputCode: React.FC<any> = React.forwardRef((props, ref) => {
    const { formik, id, onHandleChange, otherProps } = props;
    let formikProps: any = {};

    const handleChange = (e: any) => {
        onHandleChange && onHandleChange(e);
        return formik.setFieldValue(id, e.target.value)
    }

    if (formik) {
        formikProps = {
            value: formik.values[id],
            onBlur: () => formik.setFieldTouched(id),
            onChange: handleChange,
            error: formik.touched[id] && formik.errors[id],
        };
    }

    return <input
        id={id}
        name={id}
        type="text"
        className="input-code"
        onChange={formikProps.onChange}
        onBlur={formikProps.onBlur}
        value={formikProps.value}
        onInput={(e: React.ChangeEvent<HTMLInputElement>) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
        maxLength="1"
        autoComplete="on"
        ref={ref}
        {...otherProps}
    />
})

export default InputCode;