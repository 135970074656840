import React from "react";
import "./Card.scss";

const CardComponent = (props) => {
    const { children, className } = props;

    return (
        <div className={`card-component ${className || ''}`}>
            {children}
        </div>
    );
};

export default CardComponent;