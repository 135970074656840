import routes from 'constants/routes';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { createMessageService, listenToMessagesService, updateRoomService } from 'services/chats';
import { deleteMatchesByIdService } from 'services/matches';
import { getUserMatchedService } from 'services/user';
import useUser from 'stores/user';
import { Message } from 'types/Message';
import ChatComponent from '../components/ChatComponent';

const Chat: React.FC = () => {
    const history = useHistory<any>();
    const params = useParams<any>();
    const [unmatchModal, setUnmatchModal] = useState(false);
    const { user } = useUser();
    const [matchUser, setMatchUser] = useState()
    const [messages, setMessages] = useState<Message[]>([]);
    const messagesRef = useRef<any>({});
    const [newMessage, setNewMessage] = useState(false);

    const closeUnmatchModal = () => setUnmatchModal(false);
    const openUnmatchModal = () => setUnmatchModal(true);

    const listenerRef = useRef<any>();

    const messagesCallback = (message: Message) => {
        if (message.id) {
            messagesRef.current[message.id] = {
                ...messagesRef.current[message.id],
                ...message,
            }
            setMessages(Object.values(messagesRef.current));
            updateLastSeen();
        }
    };

    const unmatch = () => {
        const matchedUserId = history.location.state.matchedUserId;
        deleteMatchesByIdService(matchedUserId);
        closeUnmatchModal();
        history.push(routes.MESSAGES);
    };

    const sendMessage = async (input: string, type: "text" | "image" | "video" | "file") => {
        await createMessageService(params.id, {
            userId: user.idUser,
            message: input,
            type: type || 'text',
            createdAt: +new Date(),
        });
        setNewMessage(true);
    }

    const getMatchedUserInfo = async () => {
        const matchedUserId = history.location.state.matchedUserId;
        const data = await getUserMatchedService(matchedUserId);
        setMatchUser(data);
    }

    const updateLastSeen = () => {
        if (user.idUser)
            updateRoomService(params.id, { [user.idUser]: +new Date() });
    }

    const isFunction = (functionToCheck: () => void) => {
        return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
    }

    useEffect(() => {
        getMatchedUserInfo()

        return () => {
            if (isFunction(listenerRef.current))
                listenerRef.current()
        }
    }, [])

    useEffect(() => {
        (async () => {
            listenerRef.current = await listenToMessagesService(params.id, messagesCallback);
        })()
        updateLastSeen();
    }, [params.id]);

    return (
        <ChatComponent
            user={user}
            matchUser={matchUser}
            history={history}
            unmatch={unmatch}
            unmatchModalVisible={unmatchModal}
            openUnmatchModal={openUnmatchModal}
            closeUnmatchModal={closeUnmatchModal}
            messages={messages}
            sendMessage={sendMessage}
            newMessage={newMessage}
            setNewMessage={setNewMessage}
        />
    );
};

export default Chat;
