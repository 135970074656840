import * as React from "react"

const SunHorizonIcon = (props) => (
    <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9.719 5.448a1 1 0 0 1 1.847-.765l.957 2.31a.999.999 0 1 1-1.848.765l-.956-2.31Zm-7.036 8.118 2.31.957a.999.999 0 0 0 1.09-1.631.999.999 0 0 0-.325-.217l-2.31-.956a1 1 0 1 0-.765 1.847ZM26.625 14.6a.997.997 0 0 0 .382-.076l2.31-.957a1.001 1.001 0 1 0-.765-1.847l-2.31.956a1 1 0 0 0 .383 1.924Zm-6.607-6.3a.999.999 0 0 0 1.307-.54l.956-2.31a1 1 0 0 0-1.847-.766l-.957 2.31a.999.999 0 0 0 .541 1.306ZM30 19h-5.56a8.5 8.5 0 1 0-16.88 0H2a1 1 0 0 0 0 2h28a1 1 0 0 0 0-2Zm-4 5H6a1 1 0 0 0 0 2h20a1 1 0 0 0 0-2Z"
            fill={props.white ? "#00525c" : '#FFFFFF'}
        />
    </svg>
)

export default SunHorizonIcon
