import React from 'react';
import './Dropdown.scss';
import Select from 'react-select'

interface DropdownOption {
    value: string,
    label: string
}

interface DropdownProps {
    id?: string,
    options: DropdownOption[],
    onChange?: (option: DropdownOption) => void,
    value?: any,
    defaultValue?: any,
    isMulti?: boolean
}

export const Dropdown: React.FC<DropdownProps> = (props: any) => {
    const { id, options, value, defaultValue, onChange, isMulti = false } = props;

    return (
        <Select
            className='dropdown'
            id={id}
            options={options}
            onChange={onChange}
            value={value}
            defaultValue={defaultValue}
            isMulti={isMulti}
        />
    )
}
export default Dropdown;