import routes from 'constants/routes';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getPassionsService } from 'services/passions';
import { patchUserService } from 'services/user';
import useUser from 'stores/user';
import CreateProfileStep3Component, { Passion } from '../components/CreateProfileStep3Component';
import * as Mixpanel from 'config/mixpanel';

const CreateProfileStep3: React.FC<any> = () => {
    const history = useHistory<any>();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [passions, setPassions] = useState<any[]>([]);
    const { user, updateUser } = useUser();
    const [updated, setUpdated] = useState(false);

    const getPassions = async () => {
        try {
            const data = await getPassionsService();
            setPassions(data);
        } catch (error: any) {
            setError(error.message);
        }
    };

    useEffect(() => {
        getPassions();
    }, []);

    const submit = async (values: { passions: Passion[] }) => {
        setLoading(true);

        const body: { userFeatures: { passions: string[] } } = {
            userFeatures: {
                passions: values.passions.map((value: Passion) => value.idPassion),
            },
        };

        try {
            await patchUserService(body);
            updateUser(values);

            Mixpanel.actions.sendEvent(Mixpanel.EVENT_TYPES.ONBOARDING_INTERESTS, {
                'User ID': user.idUser,
                'Sign up date': undefined,
                'Enabled Push Notifications ': user.userSettings ? user.userSettings.allowNotifications : false,
                'Email address': user.email,
                'Phone Number': user.telephone,
                $name: `${user.firstName} ${user.lastName}`,
                'Prioritize Matches based on Location?': user.userSettings
                    ? user.userSettings.matchesByLocation
                    : false,
                Interests: values.passions,
                Troubles: user.obstacles,
                'Message Frequency Preference ': user.messagesFrecuency,
                'Message Timing Preference ': user.availability,
                'Weightloss Goal': user.weightGoal,
                '3 Important Things': user.about,
                '#Matches': undefined,
                '#Messages Sent': undefined,
                'First Login Date': undefined,
                'Last Login Date ': undefined,
            });

            setUpdated(true);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (updated) {
            setUpdated(false);
            return history.push(routes.CREATE_PROFILE_STEP_4);
        }
    }, [updated, history]);

    return (
        <CreateProfileStep3Component
            submit={submit}
            error={error}
            loading={loading}
            history={history}
            passions={passions}
            user={user}
        />
    );
};

export default CreateProfileStep3;
