import * as React from "react"

const ProfileFilledIcon = (props) => (
    <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M16 20.5a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11Z" fill="#00525C" />
        <path
            d="M16 3a13 13 0 1 0 13 13A13.015 13.015 0 0 0 16 3Zm8.22 20.301a9.963 9.963 0 0 0-3.054-2.872 7.48 7.48 0 0 1-10.332 0 9.963 9.963 0 0 0-3.054 2.872 11 11 0 1 1 16.44 0Z"
            fill={props.color || "#00525C"}
        />
    </svg>
)

export default ProfileFilledIcon
