import React, { useState } from 'react';
import ProfileCreationLayout from 'layouts/ProfileCreationLayout/ProfileCreationLayout';
import './CreateProfileStep2Component.scss';
import Button from 'components/elements/Button/Button';
import { Formik, Form } from "formik";
import routes from 'constants/routes';
import useFormikSubmitOutside from 'hooks/useFormikSubmitOutside';

const CreateProfileStep2Component: React.FC<any> = (props) => {
    const { submit, error, loading, history, user } = props;

    const [matchesByLocation, setMatchesByLocation] = useState<boolean | null>(user.userSettings.matchesByLocation);
    const { formikRef, submitForm } = useFormikSubmitOutside();

    return (
        <ProfileCreationLayout
            percentage={20} title="Would you like to prioritize matches based on location?"
            goBack={() => history.push(routes.CREATE_PROFILE_STEP_1)}
            submitButton={() =>
                <Button
                    loading={loading}
                    type="button"
                    disabled={matchesByLocation === null}
                    onClick={submitForm}>
                    Continue
                </Button>}
        >
            <div className="create-profile-form-step-2 overflow-container">
                <Formik
                    innerRef={formikRef}
                    initialValues={{ matchesByLocation: user.userSettings.matchesByLocation }}
                    onSubmit={submit}>
                    {(formik) => (
                        <Form>
                            <div className="create-profile-form-radio-container">
                                <div className={`create-profile-form-radio ${matchesByLocation ? 'selected' : ''}`}
                                    onClick={() => {
                                        setMatchesByLocation(true)
                                        formik.setFieldValue('matchesByLocation', true)
                                    }}>
                                    <p className="create-profile-form-radio-text">Yes, prioritize location</p>
                                </div>
                                <div className={`create-profile-form-radio ${matchesByLocation ? '' : matchesByLocation !== null ? 'selected' : ''}`}
                                    onClick={() => {
                                        setMatchesByLocation(false)
                                        formik.setFieldValue('matchesByLocation', false)
                                    }}>
                                    <p className="create-profile-form-radio-text">No</p>
                                </div>
                            </div>
                            {error
                                ? <div className="error-message-global">{error}</div>
                                : null
                            }
                        </Form>
                    )}
                </Formik>
            </div>
        </ProfileCreationLayout >);
}

export default CreateProfileStep2Component;