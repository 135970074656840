import React from "react";
import "./AuthLayout.scss";

export interface Props {
    login?: boolean,
    title?: string,
    subtitle?: string,
    icon?: any,
    children: React.ReactNode,
}

const AuthLayout: React.FC<Props> = (props) => {
    const { title, subtitle, icon, children } = props;

    return (
        <div className="auth-layout">
            <img className="logo" src={icon} alt="welcome" />
            <h2>{title}</h2>
            <p className="body1 auth-layout-body">{subtitle}</p>

            {children}
        </div>
    );
};

export default AuthLayout;