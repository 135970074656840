import React, { useState } from "react";
import ProfileCreationLayout from "layouts/ProfileCreationLayout/ProfileCreationLayout";
import "./CreateProfileStep7Component.scss";
import Button from "components/elements/Button/Button";
import { Formik, Form } from "formik";
import routes from "constants/routes";
import MaterialCheckbox from "../../elements/Selects/MaterialCheckBox/MaterialCheckBox"
import { Goal } from "../containers/CreateProfileStep7";
import useFormikSubmitOutside from "hooks/useFormikSubmitOutside";

const CreateProfileStep7Component: React.FC<any> = (props) => {
    const { submit, error, loading, history, goalOptions, user } = props;
    const { formikRef, submitForm } = useFormikSubmitOutside();
    const [optionSelected, setOptionSelected] = useState<Goal>(
        goalOptions.find((option: Goal) => option.name === user.weightGoal)
    );

    const isOptionSelected = (goal: Goal) => goal.name === optionSelected?.name;

    const handleSelection = (goal: Goal, setValue: (name: string, values: Goal) => void) => {
        if (!isOptionSelected(goal)) {
            setOptionSelected(goal);
            setValue('goal', goal)
        }
    }

    return (
        <ProfileCreationLayout
            percentage={70}
            title="What’s your weightloss goal?"
            goBack={() => history.push(routes.CREATE_PROFILE_STEP_6)}
            skip={() => history.push(routes.CREATE_PROFILE_STEP_8)}
            submitButton={() =>
                <Button
                    loading={loading}
                    type="button"
                    disabled={!optionSelected}
                    onClick={submitForm}>
                    Continue
                </Button>
            }
        >
            <div className="create-profile-form-step-7 overflow-container">
                <Formik
                    innerRef={formikRef}
                    initialValues={{ goal: optionSelected }}
                    onSubmit={submit}>
                    {(formik) => (
                        <Form>
                            <div className="create-profile-form-grid">
                                {goalOptions.map((goal: Goal) => (
                                    <div className={`create-profile-form-card${isOptionSelected(goal) ? ' selected' : ''}`}
                                        key={goal.name}
                                        onClick={() => handleSelection(goal, formik.setFieldValue)}
                                    >
                                        <h3>{goal.name}</h3>
                                        <p className="body1">{goal.subtitle}</p>
                                        {isOptionSelected(goal) && <MaterialCheckbox value={true} className="checkbox secondary" />}
                                    </div>
                                ))}
                            </div>
                            {error
                                ? <div className="error-message-global">{error}</div>
                                : null
                            }
                        </Form>
                    )}
                </Formik>
            </div>
        </ProfileCreationLayout >);
}

export default CreateProfileStep7Component;