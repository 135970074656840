import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getUserIdService } from 'services/user';
import { getUserToken } from 'services/utils';
import useUser from 'stores/user';
import ProfileComponent from '../components/ProfileComponent';

const Profile: React.FC = () => {
    const history = useHistory();
    const { user, setUser } = useUser();

    const logout = () => {
        setUser({});
        localStorage.clear();
    };

    const verifyIfUserIsBloqued = async () => {
        const token = getUserToken();
        const resp = await getUserIdService(token);

        if (resp.user.isBlocked) {
            logout();
            return true;
        }

        return false;
    };

    useEffect(() => {
        verifyIfUserIsBloqued();
    }, []);

    return <ProfileComponent history={history} user={user} logout={logout} />;
};

export default Profile;
