import React, { useState, useEffect } from 'react';
import RegisterComponent from '../components/RegisterComponent';
import { withRouter } from 'react-router-dom';
import { registerService } from 'services/user';
import useUser from 'stores/user';
import routes from 'constants/routes';
import * as Mixpanel from 'config/mixpanel';

const Register: React.FC<any> = (props) => {
    const { history } = props;
    const updateUser = useUser((state) => state.updateUser);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        Mixpanel.actions.sendEvent(Mixpanel.EVENT_TYPES.SIGN_UP_START);
    }, []);

    const submit = async ({ email, password }: { email: string; password: string }) => {
        setLoading(true);

        const body = { email, password };

        try {
            const response = await registerService(body);
            updateUser(response);
            history.push(routes.PHONE_VERIFICATION);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return <RegisterComponent {...props} submit={submit} history={history} loading={loading} error={error} />;
};

export default withRouter(Register);
