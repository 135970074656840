import React from "react";
import './MatchProfileComponent.scss';
import DashboardLayout from '../../../layouts/DashboardLayout/DashboardLayout'
import routes from '../../../constants/routes'
import MatchCard from "components/MatchCard/containers/MatchCard";

interface Props {
    info: any;
    history: any;
}

const MatchProfileComponent: React.FC<Props> = ({ info, history }) => {
    return (
        <DashboardLayout headerTitle="View profile" goBack={() => history.push(routes.FEED,
            { nextSingleMatch: history.location.state.nextSingleMatch })}>
            <MatchCard info={info} details />
        </DashboardLayout>
    )
}

export default MatchProfileComponent;