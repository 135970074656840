import * as React from "react"

const ChatsTeardropIcon = (props) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.196 9.072A10 10 0 0 0 2 13v8.25a1.752 1.752 0 0 0 1.75 1.75h7.083a10.02 10.02 0 0 0 9.167 6h8.25a1.752 1.752 0 0 0 1.75-1.75v-8.25a9.996 9.996 0 0 0-8.804-9.927ZM28 27h-8a8.017 8.017 0 0 1-6.96-4.054 9.988 9.988 0 0 0 8.797-11.732A7.998 7.998 0 0 1 28 18.998v8Z"
      fill={props.white ? "#00525c" : '#FFFFFF'}
    />
    <path
      stroke={props.white ? "#FFFFFF" : "#00525c"}
      strokeWidth={2}
      strokeLinecap="round"
      d="M8 11h7M8 15h7"
    />
  </svg>
)

export default ChatsTeardropIcon;
