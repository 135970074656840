import React from 'react';
import ProfileCreationLayout from 'layouts/ProfileCreationLayout/ProfileCreationLayout';
import './CreateProfileStep8Component.scss';
import Button from 'components/elements/Button/Button';
import { Formik, Form } from "formik";
import MaterialInput from 'components/elements/MaterialInput/materialInput';
import routes from 'constants/routes';
import useFormikSubmitOutside from 'hooks/useFormikSubmitOutside';

const CreateProfileStep8Component: React.FC<any> = (props) => {
    const { submit, error, loading, history, user } = props;
    const { formikRef, submitForm } = useFormikSubmitOutside();


    return (
        <ProfileCreationLayout
            percentage={80}
            title="What are three important things a match should know about you?"
            goBack={() => history.push(routes.CREATE_PROFILE_STEP_7)}
            skip={() => history.push(routes.CREATE_PROFILE_STEP_9)}
            submitButton={() =>
                <Button
                    loading={loading}
                    type="button"
                    onClick={submitForm}>
                    Continue
                </Button>
            }
        >
            <div className="create-profile-form-step-8 overflow-container">
                <Formik
                    innerRef={formikRef}
                    initialValues={{ about: user.about }}
                    onSubmit={submit}>
                    {(formik) => (
                        <Form>
                            <MaterialInput
                                id="about"
                                formik={formik}
                                className="formControl-about"
                                placeholder="Write here"
                                type="textarea"
                                label=""
                                maxLength={400}
                                variant='outlined'
                                caption={`${formik.values["about"]?.length ? formik.values["about"].length : 0}/400`}
                            />
                            {error
                                ? <div className="error-message-global">{error}</div>
                                : null
                            }
                        </Form>
                    )}
                </Formik>
            </div>
        </ProfileCreationLayout >);
}

export default CreateProfileStep8Component;