import { InputAdornment, TextField } from '@material-ui/core';
import React from 'react'
import './materialInput.scss'
import { IoClose, IoSearchOutline } from 'react-icons/io5';
import { HiOutlineCheck } from 'react-icons/hi'
import { VscClose } from 'react-icons/vsc'
import formatPhoneNumber from 'utils/formatPhoneNumber';

export interface MaterialInputI {
    placeholder: string,
    label: string,
    value?: string | number,
    className?: string,
    defaultValue?: string | number,
    InputProps?: object,
    caption?: string,
    fullWidth?: boolean
    id: string,
    multiline?: boolean,
    success?: boolean,
    fail?: boolean,
    type?: string,
    onChange?: () => void,
    resetValue?: () => void,
    disabled?: boolean,
    maxRows?: number | string,
    minRows?: number | string,
    formik?: any
    onInput?: (e: React.ChangeEvent<HTMLInputElement>) => void
    phoneNumber?: boolean,
    maxLength?: number,
    variant?: 'filled' | 'standard' | 'outlined'
}

const MaterialInput = (props: MaterialInputI) => {
    const {
        className, placeholder, label, value, defaultValue, InputProps, caption,
        fullWidth, id, multiline, success, fail, onChange, type, resetValue, disabled,
        maxRows, minRows, formik, phoneNumber, onInput, maxLength, variant } = props;

    const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const number = formatPhoneNumber(e.target.value)
        return formik.setFieldValue(id, number)
    }

    let formikProps: any = {};

    if (formik) {
        formikProps = {
            value: formik.values[id],
            onBlur: () => formik.setFieldTouched(id),
            onChange: phoneNumber ? handlePhoneNumberChange : (e: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue(id, e.target.value),

            error: formik.touched[id] && formik.errors[id],
        };
    }

    return (
        <div className={`material-input-wrapper ${className && className} ${success && 'success-outline'} ${fail && 'fail-outline'}`}>
            {!type && <TextField
                variant={variant || "filled"}
                placeholder={placeholder}
                label={label}
                disabled={disabled}
                value={formikProps.value}
                onBlur={formikProps.onBlur}
                onChange={formikProps.onChange}
                defaultValue={defaultValue}
                InputProps={success ? {
                    endAdornment: <InputAdornment position="end"><HiOutlineCheck className="success-icon" /></InputAdornment>,
                } : fail ? {
                    endAdornment: <InputAdornment position="end"><IoClose className="fail-icon" /></InputAdornment>,
                } : InputProps}
                helperText={caption}
                fullWidth={fullWidth}
                id={id}
                multiline={multiline}
                color="secondary"
                onInput={onInput}
            />}
            {type && type.toLowerCase() === 'search' && <div className="search-input-wrapper">
                <IoSearchOutline style={{ fontSize: '22px', paddingLeft: '12px', opacity: !disabled ? 1 : 0.3 }} />
                <TextField
                    variant={variant || "filled"}
                    placeholder={placeholder}
                    disabled={disabled}
                    label={label || 'Search'}
                    value={value}
                    onChange={onChange}
                    defaultValue={defaultValue}
                    InputProps={typeof value === 'string' && value.length > 0 ? {
                        endAdornment:
                            <InputAdornment
                                position="end">
                                <VscClose
                                    style={{ fontSize: '28px', cursor: 'pointer' }}
                                    onClick={resetValue} />
                            </InputAdornment>,
                    } : {}}
                    fullWidth={fullWidth}
                    id={id}
                    multiline={multiline}
                    color="secondary"
                />
            </div>}
            {type && type.toLowerCase() === 'textarea' && <TextField
                variant={variant || "filled"}
                placeholder={placeholder}
                label={label}
                value={formikProps.value}
                onBlur={formikProps.onBlur}
                onChange={formikProps.onChange}
                disabled={disabled}
                maxRows={maxRows || 8}
                minRows={minRows || 4}
                defaultValue={defaultValue}
                InputProps={success ? {
                    endAdornment: <InputAdornment position="end"><HiOutlineCheck className="success-icon" /></InputAdornment>,
                } : fail ? {
                    endAdornment: <InputAdornment position="end"><IoClose className="fail-icon" /></InputAdornment>,
                } : InputProps}
                helperText={caption}
                fullWidth={fullWidth}
                id={id}
                multiline
                color="secondary"
                inputProps={{
                    maxLength,
                }}
            />}
        </div>
    )
}
export default MaterialInput;