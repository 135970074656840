import React from 'react';
import Header from 'components/elements/Header/Header';
import AuthLayout from 'layouts/AuthLayout/AuthLayout';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import Button from 'components/elements/Button/Button';
import Input from 'components/elements/Input/Input';
import routes from 'constants/routes';
import './phoneVerification.scss';
import MaterialInput from 'components/elements/MaterialInput/materialInput';
import Logo from 'components/elements/Logo/Logo';

const PhoneVerificationComponent: React.FC<any> = (props) => {
    const { error, loading, history, submit, goBack } = props;
    const historyValues = history.location.state?.values;

    return (
        <div className="phone-verification-component">
            <Logo />
            <Header goBack={goBack} title="Phone verification" />
            <AuthLayout
                title="Verify your phone"
                subtitle="Please, enter your number below"
                icon={require('../../../assets/images/mobile-icon.png')}
            >
                <div className="phone-verification-form">
                    <Formik
                        initialValues={{
                            prefix: historyValues?.prefix || '1',
                            number: historyValues?.number || '',
                        }}
                        isInitialValid={false}
                        validationSchema={yup.object().shape({
                            prefix: yup.string().required('Prefix required').min(1).max(2),
                            number: yup.string().required('Phone number is a required field'),
                        })}
                        onSubmit={submit}
                    >
                        {(formik) => (
                            <>
                                <Form>
                                    <div className="inputs-wrapper">
                                        <div className="input-small">
                                            <Input
                                                id="prefix"
                                                formik={formik}
                                                type="text"
                                                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                    (e.target.value = e.target.value
                                                        .replace(/[^0-9.]/g, '')
                                                        .replace(/(\..*)\./g, '$1'))
                                                }
                                                maxLength={3}
                                                prefixText="+"
                                            />
                                        </div>
                                        <div className="input-large">
                                            <MaterialInput
                                                id="number"
                                                formik={formik}
                                                className="formControl"
                                                placeholder="Phone number"
                                                label="Phone number"
                                                phoneNumber
                                                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                    (e.target.value = e.target.value
                                                        .replace(/[^0-9.]/g, '')
                                                        .replace(/(\..*)\./g, '$1'))
                                                }
                                            />
                                        </div>
                                    </div>
                                    {error ? <div className="error-message-global">{error}</div> : null}

                                    <Button
                                        className="phone-verification-button"
                                        loading={loading}
                                        type="button"
                                        disabled={!formik.isValid}
                                        onClick={formik.handleSubmit}
                                    >
                                        Next
                                    </Button>
                                </Form>
                                <p className="text-link">
                                    Check our{' '}
                                    <a href={routes.PRIVACY_POLICY} target="_blank" className="policy-link">
                                        Privacy Policy
                                    </a>
                                </p>
                            </>
                        )}
                    </Formik>
                </div>
            </AuthLayout>
        </div>
    );
};

export default PhoneVerificationComponent;
