import routes from 'constants/routes';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { patchUserService } from 'services/user';
import useUser from 'stores/user';
import CreateProfileStep1Component from '../components/CreateProfileStep1Component';
import * as Mixpanel from 'config/mixpanel';

const CreateProfileStep1: React.FC<any> = (props) => {
    const history = useHistory<any>();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const { user, updateUser } = useUser();
    const [updated, setUpdated] = useState(false);

    const submit = async (values: { firstName: string; lastName: string }) => {
        setLoading(true);

        const body = {
            userFeatures: {
                firstName: values.firstName,
                lastName: values.lastName,
            },
        };

        try {
            await patchUserService(body);
            updateUser(values);

            Mixpanel.actions.sendEvent(Mixpanel.EVENT_TYPES.ONBOARDING_NAME, {
                'User ID': user.idUser,
                'Sign up date': undefined,
                'Enabled Push Notifications ': user.userSettings ? user.userSettings.allowNotifications : false,
                'Email address': user.email,
                'Phone Number': user.telephone,
                $name: `${values.firstName} ${values.lastName}`,
                'Prioritize Matches based on Location?': user.userSettings
                    ? user.userSettings.matchesByLocation
                    : false,
                Interests: user.passions,
                Troubles: user.obstacles,
                'Message Frequency Preference ': user.messagesFrecuency,
                'Message Timing Preference ': user.availability,
                'Weightloss Goal': user.weightGoal,
                '3 Important Things': user.about,
                '#Matches': undefined,
                '#Messages Sent': undefined,
                'First Login Date': undefined,
                'Last Login Date ': undefined,
            });

            setUpdated(true);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (updated) {
            setUpdated(false);
            return history.push(routes.CREATE_PROFILE_STEP_2);
        }
    }, [updated, history]);

    return (
        <CreateProfileStep1Component
            {...props}
            submit={submit}
            error={error}
            loading={loading}
            history={history}
            user={user}
        />
    );
};

export default CreateProfileStep1;
