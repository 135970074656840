import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import AuthLayout from 'layouts/AuthLayout/AuthLayout';
import Button from 'components/elements/Button/Button';
import Input from 'components/elements/Input/Input';
import './LoginComponent.scss';
import routes from 'constants/routes';
import { Link } from 'react-router-dom';
import { Dialog } from '@material-ui/core';

const LoginComponent: React.FC<any> = (props) => {
    const { loading, submit, error, history, userBloqued, setUserBloqued } = props;
    const [showPass, setShowPass] = useState(false);

    return (
        <AuthLayout
            title="Welcome"
            subtitle="Find your weightloss partner"
            icon={require('../../../assets/images/logo.png')}
        >
            <div className="login-component">
                <Formik
                    initialValues={{ email: '', password: '' }}
                    validationSchema={yup.object().shape({
                        email: yup.string().email('Invalid email format').required('Email is a required field'),
                        password: yup.string().required('Password is a required field'),
                    })}
                    onSubmit={(values) => submit(values)}
                >
                    {(formik) => (
                        <>
                            <Form>
                                <Input id="email" formik={formik} type="text" placeholder="Email" />

                                <Input
                                    id="password"
                                    formik={formik}
                                    type={showPass ? 'text' : 'password'}
                                    className="formControl"
                                    placeholder="Password"
                                    rightIcon={
                                        showPass
                                            ? require('../../../assets/images/eye.png')
                                            : require('../../../assets/images/eye-closed.png')
                                    }
                                    rightIconFunc={() => setShowPass(!showPass)}
                                />

                                <Link className="forgot-password" to={routes.FORGOT_PASSWORD}>
                                    Forgot password?
                                </Link>

                                {error ? (
                                    <div className="error-message-global">Wrong credentials. Please try again.</div>
                                ) : null}

                                <Button loading={loading} type="submit" onClick={formik.handleSubmit}>
                                    Log in
                                </Button>

                                <div className="separator">
                                    <div className="separator-line" />
                                    <span className="separator-text">or</span>
                                    <div className="separator-line" />
                                </div>

                                <span className="sign-up" onClick={() => history.push(routes.REGISTER)}>
                                    Sign up
                                </span>
                            </Form>
                        </>
                    )}
                </Formik>
            </div>
            <Dialog className="modal" open={userBloqued} onClose={() => setUserBloqued(false)}>
                <div className="match-modal-login">
                    <img src={require('assets/images/image-matches-blank.png')} alt="" />
                    <h3>Your user has been blocked</h3>
                    <p>
                        Please contact the administrators at{' '}
                        <a className="emphasis" href={'mailto:contact@turnip.com'} target="_top">
                            contact@turnip.com
                        </a>{' '}
                        if you want access to our App.
                    </p>
                </div>
            </Dialog>
        </AuthLayout>
    );
};

export default LoginComponent;
