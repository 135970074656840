import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import React, { useEffect, useRef, useState } from 'react';
import '../components/ChatComponent.scss';
import OutsideClickHandler from 'react-outside-click-handler';
import routes from 'constants/routes';
import { Modal, Dialog } from '@material-ui/core';
import Button from 'components/elements/Button/Button';
import PlusCircleIcon from 'components/icons/PlusCircleIcon';
import { Message } from 'types/Message';
import _ from 'lodash';
import User from 'models/User';
import { uploadImageService } from 'services/user';

interface Props {
    user: User;
    matchUser: any;
    history: any;
    unmatch: () => void;
    unmatchModalVisible: boolean;
    openUnmatchModal: () => void;
    closeUnmatchModal: () => void;
    messages: Message[];
    sendMessage: (input: string, type: 'text' | 'image' | 'video' | 'file') => void;
    newMessage: boolean;
    setNewMessage: any;
}

const ChatComponent: React.FC<Props> = (props) => {
    const {
        user,
        history,
        unmatch,
        unmatchModalVisible,
        openUnmatchModal,
        closeUnmatchModal,
        messages,
        sendMessage,
        matchUser,
        newMessage,
        setNewMessage,
    } = props;
    const [dropdown, setDropdown] = useState(false);
    const textareaRef = useRef<any>();
    const [expandable, setExpandable] = useState(false);
    const [file, setFile] = useState<any>(null);
    const [input, setInput] = useState('');
    const chatRef = useRef<any>();

    const handleSendMessage = (input: string, type: 'text' | 'image' | 'video' | 'file') => {
        sendMessage(input, type);
        setInput('');
        removeFile();
    };

    useEffect(() => {
        if (newMessage) {
            scrollToBottom();
            setNewMessage(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newMessage]);

    const handleFile = (file: any) => {
        setFile({ file, preview: URL.createObjectURL(file) });
    };

    const removeFile = () => setFile(null);

    let fileInputRef = useRef<any>(null);

    const onAttach = () => {
        fileInputRef.current.click();
    };

    const getFileType = (type: string) => {
        if (type.includes('image')) return 'image';
        if (type.includes('video')) return 'video';
        if (type.includes('application')) return 'file';
        return 'image';
    };

    const onChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const fileUploaded = e.target.files[0];
            handleFile(fileUploaded);
            const fileType = getFileType(fileUploaded.type);
            try {
                const imageLink: string | any = await uploadImageService(fileUploaded);
                handleSendMessage(imageLink, fileType);
            } catch (error: any) {
                console.log(error);
                alert(error?.message);
            } finally {
                removeFile();
            }
        }
    };

    const getNumberOfLinesOfTextarea = () => {
        if (textareaRef?.current) {
            const taLineHeight = 20;
            const taHeight = textareaRef.current.scrollHeight;
            let numberOfLines = Math.floor(taHeight / taLineHeight) - 1;
            setExpandable(false);
            if (numberOfLines > 2) setExpandable(true);
            return numberOfLines;
        }
        return 1;
    };

    const formatDate = (date: number) =>
        new Date(date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
    const formatHour = (date: number) =>
        new Date(date).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });

    const groupMessagesByDate = (messages: Message[]) => {
        const messagesOrdered = messages.sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0));
        return _.groupBy(messagesOrdered, ({ createdAt }: { createdAt: number }) => formatDate(createdAt));
    };

    const groupMessagesByUser = (messages: any[]) => {
        let newMessages: any[] = [];
        messages
            .sort((a, b) => a.createdAt - b.createdAt)
            .forEach((message: Message, index: number) => {
                if (message?.userId === messages[index - 1]?.userId) {
                    let lastGroup = newMessages.pop();
                    newMessages = [...newMessages, [...lastGroup, message]];
                } else {
                    newMessages = [...newMessages, [message]];
                }
            });
        return newMessages;
    };

    const getYesterday = () => new Date().getTime() - 24 * 60 * 60 * 1000;

    const checkDate = (date: any) => {
        const now = formatDate(Date.now());
        const yesterday = formatDate(getYesterday());
        if (date === now) return 'Today';
        if (date === yesterday) return 'Yesterday';
        return date;
    };

    const scrollToBottom = () => (chatRef.current.scrollTop = chatRef.current.scrollHeight);

    const getMessage = (message: any) => {
        switch (message.type) {
            case 'text':
                return <p>{message.message}</p>;
            case 'image':
                return (
                    <a href={message.message} target="_blank" rel="noopener noreferrer">
                        {' '}
                        <img src={message.message} alt="message" />
                    </a>
                );
            case 'video':
                return (
                    <a href={message.message} target="_blank" rel="noopener noreferrer">
                        {' '}
                        <video>
                            <source src={message.message}></source>
                        </video>
                    </a>
                );
            case 'file':
                return (
                    <a href={message.message} target="_blank" rel="noopener noreferrer">
                        {message.message}
                    </a>
                );
            default:
                return <p>{message.message}</p>;
        }
    };

    // console.log({
    //     ordered: Object.entries(groupMessagesByDate(messages)),
    // });

    return (
        <DashboardLayout
            headerTitle={matchUser?.firstName && `${matchUser?.firstName} ${matchUser?.lastName}`}
            goBack={() => history.push(routes.MESSAGES)}
            noPadding
            right={
                <OutsideClickHandler onOutsideClick={() => setDropdown(false)}>
                    <div className="header-dots" onClick={() => setDropdown(!dropdown)}>
                        <img src={require('../../../assets/images/dots-three-vertical.png')} alt="dots" />
                        {dropdown && (
                            <div className="header-dropdown">
                                <div className="dropdown-item" onClick={openUnmatchModal}>
                                    <PlusCircleIcon height={26} width={26} />
                                    <p>Unmatch</p>
                                </div>
                            </div>
                        )}
                    </div>
                </OutsideClickHandler>
            }
        >
            <Dialog className="modal" open={unmatchModalVisible}>
                <div className="unmatch-modal">
                    <PlusCircleIcon height={57} width={57} />
                    <div className="unmatch-modal-buttons">
                        <h2>Unmatch?</h2>
                        <p>This user will not appear in your messages or in your feed again.</p>
                        <Button onClick={unmatch}>Unmatch</Button>
                        <Button secondary onClick={closeUnmatchModal}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Dialog>
            <div className="chat">
                <div className="chat-messages-container" ref={chatRef}>
                    {messages &&
                        Object.entries(groupMessagesByDate(messages)).map((groupByDate, index) => (
                            <div className="chat-messages-wrapper" key={index}>
                                <div className="chat-date-tag">
                                    <span>{checkDate(groupByDate[0])}</span>
                                </div>
                                {groupMessagesByUser(groupByDate[1]).map((groupByUser, index) =>
                                    groupByUser[0].userId === user.idUser ? (
                                        <div className="chat-message right" key={index}>
                                            {groupByUser.map((message: any) => (
                                                <div
                                                    className={`chat-bubble${
                                                        message.type !== 'text'
                                                            ? message.type === 'video'
                                                                ? ' file video'
                                                                : ' file'
                                                            : ''
                                                    }`}
                                                    key={message.id}
                                                >
                                                    {getMessage(message)}
                                                    <span className="chat-bubble-time">
                                                        {formatHour(message.createdAt)}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="chat-message left" key={index}>
                                            <img
                                                className="chat-message-profile-image"
                                                src={
                                                    matchUser?.imgProfile ||
                                                    require('../../../assets/images/user-placeholder.jpg')
                                                }
                                                alt="person"
                                            />
                                            {groupByUser.map((message: any) => (
                                                <div
                                                    className={`chat-bubble${
                                                        message.type !== 'text'
                                                            ? message.type === 'video'
                                                                ? ' file video'
                                                                : ' file'
                                                            : ''
                                                    }`}
                                                    key={message.id}
                                                >
                                                    {getMessage(message)}
                                                    <span className="chat-bubble-time">
                                                        {formatHour(message.createdAt)}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    )
                                )}
                            </div>
                        ))}
                </div>
                <div className="chat-input-container">
                    <img
                        className="chat-input-attach"
                        src={require('../../../assets/images/paper-clip.png')}
                        alt="attach"
                        onClick={onAttach}
                    />
                    <input
                        ref={fileInputRef}
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={onChangeFile}
                        style={{ display: 'none' }}
                    />
                    <div className="chat-grow">
                        <textarea
                            className={`chat-input${expandable ? ' chat-expand' : ''}`}
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            name="text"
                            id="text"
                            rows={1}
                            placeholder="Write here..."
                            ref={textareaRef}
                            onInput={() => {
                                if (textareaRef) {
                                    if (getNumberOfLinesOfTextarea() > 6) return;
                                    textareaRef.current.parentNode.dataset.replicatedValue = textareaRef.current.value;
                                }
                            }}
                        />
                    </div>
                    <button className="chat-input-send" onClick={() => handleSendMessage(input, 'text')}>
                        <img src={require('../../../assets/images/paper-plane-right.png')} alt="send" />
                    </button>
                </div>
            </div>
        </DashboardLayout>
    );
};
export default ChatComponent;
