import React from 'react';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import './FeedComponent.scss';
import MatchCard from 'components/MatchCard/containers/MatchCard';
import routes from 'constants/routes';
import { useHistory } from 'react-router-dom';
import { Dialog } from '@material-ui/core';
import Button from 'components/elements/Button/Button';
import Lottie from 'react-lottie';
import match from '../../../assets/lotties/match.json';

interface Props {
    singleMatches: any[];
    onLike: (idUserMatched: string) => void;
    onDislike: (idUser: string) => void;
    onKeepViewing: () => void;
    onMessage: () => void;
    openMatchModal: boolean;
    nextSingleMatch: number;
    history: any;
    loading: boolean;
}

const FeedComponent: React.FC<Props> = (props) => {
    const { singleMatches, onLike, onDislike, onKeepViewing, openMatchModal, nextSingleMatch, onMessage, loading } =
        props;
    const history = useHistory();
    const isEmpty = !singleMatches[nextSingleMatch] && !loading;
    // console.log('singleMatches[nextSingleMatch]', singleMatches[nextSingleMatch]);

    const matchLottieOptions = {
        autoplay: true,
        animationData: match,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <DashboardLayout logo>
            <div className="feed">
                <MatchCard
                    info={singleMatches[nextSingleMatch]}
                    onClick={() =>
                        history.push(routes.MATCH_PROFILE, {
                            user: singleMatches[nextSingleMatch],
                            nextSingleMatch,
                        })
                    }
                    onLike={() => onLike(singleMatches[nextSingleMatch].idUser)}
                    onDislike={() => onDislike(singleMatches[nextSingleMatch].idUser)}
                />
            </div>
            <Dialog className="modal" open={openMatchModal}>
                <div className="match-modal">
                    <div className="match-modal-buttons">
                        <Lottie options={matchLottieOptions} height={192} width={192} />
                        <h2>Matched!</h2>
                        <Button onClick={onKeepViewing}>Keep viewing</Button>
                        <Button secondary onClick={onMessage}>
                            Message
                        </Button>
                    </div>
                </div>
            </Dialog>
            <Dialog className="modal" open={isEmpty}>
                <div className="empty-modal">
                    <h3>Woops! Looks like you are out of matches</h3>
                    <p>
                        At the moment, there are no other users for you to see on the app. Turnip is in its beta phase
                        so more people are signing up everyday. If you have any matches, reach out to them! Otherwise,
                        check in later to see if you get matched with someone or see if there are more people on the
                        app.
                    </p>
                    <div className="empty-modal-buttons">
                        <Button onClick={() => history.push(routes.MESSAGES)}>View my messages</Button>
                    </div>
                </div>
            </Dialog>
        </DashboardLayout>
    );
};

export default FeedComponent;
