export default {
    LOGIN: '/login',
    REGISTER: '/register',
    PRIVACY_POLICY: 'https://api.dev.weightloss.pitagorinesgroup.com/images/1653400491868-b7e926e1-6660-42b1-abe7-1b86e5a8e452.pdf',
    PHONE_VERIFICATION: '/phone-verification',
    SMS_CONFIRMATION: '/phone-confirmation',
    FEED: '/feed',
    MATCH_PROFILE: '/view-profile',
    MESSAGES: '/messages',
    PROFILE: '/profile',
    FEEDBACK: '/feedback',
    FORGOT_PASSWORD: '/forgot-password',
    CREATE_NEW_PASSWORD: '/recover',
    PROFILE_CREATION: '/profile-creation',
    COMUNITY_RULES: '/comunity-rules',
    WELCOME: '/welcome',
    CREATE_PROFILE_STEP_1: '/create-profile/step-1',
    CREATE_PROFILE_STEP_2: '/create-profile/step-2',
    CREATE_PROFILE_STEP_3: '/create-profile/step-3',
    CREATE_PROFILE_STEP_4: '/create-profile/step-4',
    CREATE_PROFILE_STEP_5: '/create-profile/step-5',
    CREATE_PROFILE_STEP_6: '/create-profile/step-6',
    CREATE_PROFILE_STEP_7: '/create-profile/step-7',
    CREATE_PROFILE_STEP_8: '/create-profile/step-8',
    CREATE_PROFILE_STEP_9: '/create-profile/step-9',
    PROFILE_CONFIRMATION: '/create-profile/confirmation',
    EDIT_PROFILE: '/edit',
    EDIT_PASSIONS: '/edit-passions',
    EDIT_OBSTACLES: '/edit-obstacles'
}