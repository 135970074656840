import * as React from "react"

const LocalIcon = (props) => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M10 10.625C11.3807 10.625 12.5 9.50574 12.5 8.12503C12.5 6.74432 11.3807 5.62503 10 5.62503C8.61929 5.62503 7.5 6.74432 7.5 8.12503C7.5 9.50574 8.61929 10.625 10 10.625Z"
            stroke={props.color ? props.color : "#C6C6C8"}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M16.25 8.12503C16.25 13.75 10 18.125 10 18.125C10 18.125 3.75 13.75 3.75 8.12503C3.75 6.46743 4.40848 4.87772 5.58058 3.70561C6.75269 2.53351 8.3424 1.87503 10 1.87503C11.6576 1.87503 13.2473 2.53351 14.4194 3.70561C15.5915 4.87772 16.25 6.46743 16.25 8.12503V8.12503Z"
            stroke={props.color ? props.color : "#C6C6C8"}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);
export default LocalIcon
