import React, { useState } from 'react';
import AuthLayout from 'layouts/AuthLayout/AuthLayout';
import Button from 'components/elements/Button/Button';
import Input from 'components/elements/Input/Input';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import './RegisterComponent.scss';
import Header from 'components/elements/Header/Header';
import routes from 'constants/routes';
import MaterialCheckBox from 'components/elements/Selects/MaterialCheckBox/MaterialCheckBox';
import { Link } from 'react-router-dom';
import Logo from 'components/elements/Logo/Logo';

const RegisterComponent: React.FC<any> = (props) => {
    const { submit, history, loading, error } = props;
    const [showPass, setShowPass] = useState(false);
    const historyValues = history.location.state?.values;

    return (
        <div className="register-component">
            <Logo />
            <Header goBack={() => history.push(routes.LOGIN)} title="Sign up" />
            <AuthLayout
                title="Welcome!"
                subtitle="Find your weightloss partner"
                icon={require('../../../assets/images/logo.png')}
            >
                <div className="register-form">
                    <Formik
                        initialValues={{
                            email: historyValues?.email || '',
                            password: historyValues?.password || '',
                            adult: historyValues?.adult || false,
                            acceptTerms: historyValues?.acceptTerms || false,
                        }}
                        isInitialValid={false}
                        validationSchema={yup.object().shape({
                            email: yup.string().email('Invalid email format').required('Email is a required field'),
                            password: yup.string().min(8).required('Password is a required field'),
                            adult: yup
                                .boolean()
                                .required('You must be 18 or older')
                                .oneOf([true], 'You must be 18 or older'),
                            acceptTerms: yup
                                .boolean()
                                .required('You must accept the Terms and Conditions and Privacy Policy')
                                .oneOf([true], 'You must accept the Terms and Conditions and Privacy Policy'),
                        })}
                        onSubmit={(values) => submit(values)}
                    >
                        {(formik) => (
                            <>
                                <Form>
                                    <Input id="email" formik={formik} type="text" placeholder="Email" />
                                    <Input
                                        id="password"
                                        formik={formik}
                                        type={showPass ? 'text' : 'password'}
                                        className="formControl"
                                        placeholder="Password"
                                        rightIcon={
                                            showPass
                                                ? require('../../../assets/images/eye.png')
                                                : require('../../../assets/images/eye-closed.png')
                                        }
                                        rightIconFunc={() => setShowPass(!showPass)}
                                    />
                                    <div className="register-checkbox">
                                        <MaterialCheckBox
                                            id="adult"
                                            value={formik.values.adult}
                                            onChange={() => formik.setFieldValue('adult', !formik.values.adult)}
                                        />
                                        <p>I am 18 or older</p>
                                    </div>
                                    <div className="register-checkbox">
                                        <MaterialCheckBox
                                            id="acceptTerms"
                                            value={formik.values.acceptTerms}
                                            onChange={() =>
                                                formik.setFieldValue('acceptTerms', !formik.values.acceptTerms)
                                            }
                                        />
                                        <p>
                                            I accept the{' '}
                                            <a href={routes.PRIVACY_POLICY} target="_blank" className="policy-link">
                                                Terms and Conditions and Privacy Policy
                                            </a>
                                        </p>
                                    </div>
                                    {error ? <div className="error-message-global">{error}</div> : null}
                                    <Button
                                        loading={loading}
                                        type="button"
                                        disabled={!formik.isValid}
                                        onClick={formik.handleSubmit}
                                    >
                                        Sign up
                                    </Button>
                                </Form>
                            </>
                        )}
                    </Formik>
                </div>
            </AuthLayout>
        </div>
    );
};

export default RegisterComponent;
