import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './MatchCardComponent.scss';
import ChatTeardropDots from 'components/icons/ChatTeardropDots';
import ClockIcon from 'components/icons/ClockIcon';
import User from 'models/User';
import MatchCardSkeleton from './MatchCardSkeleton';

interface Props {
    info: User;
    onClick?: () => void;
    onLike?: () => void;
    onDislike?: () => void;
    details?: boolean;
}

const MatchCardComponent: React.FC<Props> = ({ info, onClick, details, onLike, onDislike }) => {
    if (!info) return <MatchCardSkeleton />;
    return (
        <div className={`match-card${!!details ? ' details' : ''}`}>
            <Carousel className="carousel" showThumbs={false} showArrows={false} emulateTouch>
                {[1].map((index) => (
                    <div key={index}>
                        {!!info?.imgProfile ? (
                            <img src={info?.imgProfile} alt="person" />
                        ) : (
                            <img src={require('../../../assets/images/user-placeholder.jpg')} alt="person" />
                        )}
                    </div>
                ))}
            </Carousel>
            <div className={`match-content${details ? ' no-clickable' : ''}`} onClick={onClick}>
                <h3>
                    {info?.firstName} {info?.lastName}
                </h3>
                <p className="match-about">{info?.about}</p>
                {details && (
                    <>
                        <p className="match-category-title">Communication & Availability</p>
                        <div className="match-row">
                            <ChatTeardropDots />
                            <p>{info?.messagesFrecuency}</p>
                        </div>
                        <div className="match-line" />
                        <div className="match-row">
                            <ClockIcon />
                            <p>{info?.availability}</p>
                        </div>
                        <div className="match-separator" />
                    </>
                )}
                <p className="match-category-title">Interests</p>
                <div className="tags-container">
                    {info?.passions?.map((passion) => (
                        <div key={passion.idPassion} className="tag">
                            <span>{passion.namePassion}</span>
                        </div>
                    ))}
                </div>
                {details && (
                    <>
                        <div className="match-separator" />
                        <p className="match-category-title">Obstacles</p>
                        <div className="tags-container">
                            {info?.obstacles?.map((obstacle) => (
                                <div key={obstacle.idObstacle} className="tag">
                                    <span>{obstacle.nameObstacle}</span>
                                </div>
                            ))}
                        </div>
                    </>
                )}
                {!details && (
                    <div className="match-buttons">
                        <button
                            onClick={(e) => {
                                onDislike && onDislike();
                                e.stopPropagation();
                            }}
                            className="dislike-button"
                        >
                            <img src={require('../../../assets/images/icon-x.png')} alt="check" />
                        </button>
                        <button
                            onClick={(e) => {
                                onLike && onLike();
                                e.stopPropagation();
                            }}
                            className="like-button"
                        >
                            <img src={require('../../../assets/images/icon-done.png')} alt="check" />
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MatchCardComponent;
