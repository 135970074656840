import React from 'react';
import './ForgotPasswordComponent.scss'
import Header from 'components/elements/Header/Header';
import AuthLayout from 'layouts/AuthLayout/AuthLayout';
import routes from 'constants/routes';
import { Formik, Form } from "formik";
import * as yup from "yup";
import Button from "components/elements/Button/Button";
import Input from "components/elements/Input/Input";
import checkIfSubmitButtonisDisabled from 'utils/checkIfSubmitButtonisDisabled';

const ForgotPasswordComponent: React.FC<any> = (props) => {
    const { submit, history, loading, error, success } = props;

    return (
        <div className="forgot-password-component">
            <Header goBack={() => history.push(routes.LOGIN)} title="Forgot password" />
            <AuthLayout
                title="Recover password"
                subtitle="Please, enter your email below. We will send you an email so you can recover your password."
                icon={require('../../../assets/images/pass-icon.png')}
            >
                <div className="forgot-password-form">
                    <Formik
                        initialValues={{ email: "" }}
                        validationSchema={yup.object().shape({
                            email: yup.string().email("Invalid email format").required("Email required")
                        })}
                        onSubmit={(values) => submit(values)}>
                        {(formik) => (
                            <Form>
                                <Input
                                    id="email"
                                    formik={formik}
                                    type="text"
                                    placeholder="Email"
                                />

                                {error
                                    && <div className="error-message-global">{error}</div>
                                }
                                {success
                                    && <div className="success-message">Please follow the instructions sent to your email.</div>
                                }

                                <Button
                                    className="forgot-password-button"
                                    loading={loading}
                                    type="button"
                                    disabled={checkIfSubmitButtonisDisabled(formik, ['email'])}
                                    onClick={formik.handleSubmit}>
                                    Next
                                </Button>
                            </Form>
                        )}
                    </Formik>
                    <p className="text-link">Check our <a href="">Privacy Policy</a></p>
                </div>
            </AuthLayout>
        </div >)
}

export default ForgotPasswordComponent