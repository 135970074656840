
interface Options {
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    bodyReq?: object,
    params?: string,
    token?: string,
    headers?: object,
    hideHeaders?: boolean
}

export default async function customFetch(URL: string, { method = "GET", bodyReq, params, token, headers = {}, hideHeaders = false }: Options) {
    if (!URL) throw new Error("invalid URL");

    const finalURL: string = params ? URL + "/" + params : URL;
    const customHeaders: object = token
        ? {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            ...headers,
        }
        : { "Content-Type": "application/json", ...headers };

    let requestOptions: any = {
        method,
        body: bodyReq ? JSON.stringify(bodyReq) : null,
    }

    if (!hideHeaders) {
        requestOptions.headers = customHeaders;
    }

    let response = await fetch(finalURL, requestOptions);
    let jsonResponse: any = response;
    const responseType = response?.headers?.get("Content-Type");

    if (responseType && responseType.indexOf("application/json") > -1) {
        jsonResponse = await response.json();
    } else {
        jsonResponse = await response.text();
    }

    const code = Math.floor(response.status / 100);
    if (code !== 2 || jsonResponse?.error) throw jsonResponse?.error;

    return jsonResponse;
}
