export default function (number: string) {
    let newStr = ''

    number.split('').forEach((char, index) => {
        let lastSpaceIndex = newStr.lastIndexOf(' ');
        if (index === 3 || index === 6 || index === 10 || index === 14 || index === 18)
            return newStr = newStr + ' ' + char;
        else if (index > 10 && index === lastSpaceIndex + 5)
            return newStr = newStr + ' ' + char;
        newStr = newStr + char;
    })

    return newStr;
}