import customFetch from './customFetch';
import { endpoints } from './endpoints';
import { ServerError } from './errorHandling';
import { getUserId, getUserToken } from './utils';
import { db, firebaseApp } from '../config/firebase';
import { Room } from 'types/Room';
import { Message } from 'types/Message';

export const getChatsService = async () => {
    const token = getUserToken();

    try {
        let response = await customFetch(`${endpoints.chats}/user`, {
            method: 'GET',
            token,
        });

        return response;
    } catch (err: any) {
        throw new ServerError(err);
    }
};

export const listenToRoomsService = async (roomsIds: string[], callback: (r: Room) => void) => {
    let observers = roomsIds.map((roomId) => {
        try {
            return db
                .collection('room')
                .where(firebaseApp.firestore.FieldPath.documentId(), '==', roomId)
                .onSnapshot((snapshot: any) => {
                    snapshot.docChanges().forEach((change: any) => {
                        callback({
                            ...change.doc.data(),
                            id: change.doc.id,
                        });
                    });
                });
        } catch (e) {
            return console.log('error getGroupsService', e);
        }
    });
    return observers;
};

export const listenToMessagesService = async (roomId: string, callback: (r: Message) => void) => {
    try {
        return db
            .collection('room')
            .doc(roomId)
            .collection('messages')
            .orderBy('createdAt', 'desc')
            .onSnapshot((snapshot: any) => {
                snapshot.docChanges().forEach((change: any) => {
                    callback({
                        ...change.doc.data(),
                        id: change.doc.id,
                    });
                });
            });
    } catch (e) {
        return console.log('error getGroupsService', e);
    }
};

export const updateRoomService = async (roomId: string, data: any) => {
    try {
        console.log('updateRoomService', roomId, data)
        return db
            .collection('room')
            .doc(roomId)
            .update(data);
    } catch (e) {
        return console.log('error getGroupsService', e);
    }
};

export const createMessageService = async (roomId: string, message: Message) => {
    try {
        const roomRef = db.collection('room').doc(roomId);
        roomRef.collection('messages').add(message);
        updateRoomService(roomId, { lastMessage: message })
    } catch (e) {
        return console.log('error getGroupsService', e);
    }
};

export const getUnreadMessagesCount = async (roomId: string, lastReadTimestamp: number) => {
    if (!roomId || lastReadTimestamp === undefined) return 0;
    const unreadMessages = await db
        .collection("room")
        .doc(roomId)
        .collection("messages")
        .where("createdAt", ">", lastReadTimestamp)
        .get();
    return unreadMessages.docs.map(doc => doc.data()).filter(message => message.userId !== getUserId()).length;
}
