import { ServerError } from './errorHandling';
import { endpoints } from './endpoints';
import customFetch from './customFetch';
import { getUserToken } from './utils';
import Compressor from 'compressorjs';

export const loginService = async (credentials: { email: string; password: string }) => {
    try {
        let response = await customFetch(`${endpoints.users}/login`, {
            method: 'POST',
            bodyReq: credentials,
        });

        const data = await getUserIdService(response.token);
        return { ...data, token: response.token };
    } catch (err) {
        throw new ServerError(err);
    }
};

export const registerService = async (credentials: { email: string; password: string }) => {
    try {
        let response = await customFetch(`${endpoints.users}/register`, {
            method: 'POST',
            bodyReq: credentials,
        });

        if (response.error) throw response;

        const userInfo = await getUserIdService(response.token);
        return { ...userInfo, token: response.token };
    } catch (err: any) {
        throw new ServerError(err);
    }
};

export const getUserIdService = async (token?: string) => {
    const userToken = token || getUserToken();
    try {
        const filters: any = {
            include: [
                {
                    relation: 'userSettings',
                },
                {
                    relation: 'user',
                },
            ],
        };
        let response = await customFetch(`${endpoints.user}/me?filter=${JSON.stringify(filters)}`, {
            method: 'GET',
            token: userToken,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw new ServerError(err);
    }
};

export const getUserInfoService = async (id: string) => {
    const token = getUserToken();
    try {
        let response = await customFetch(`${endpoints.user}/${id}`, {
            method: 'GET',
            token,
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw new ServerError(err);
    }
};

export const getUserMatchedService = async (id: string) => {
    const token = getUserToken();
    try {
        let response = await customFetch(`${endpoints.userMatched}/${id}`, {
            method: 'GET',
            token,
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw new ServerError(err);
    }
};

export const getRecoverPasswordLink = async (email: string) => {
    try {
        let response = await customFetch(`${endpoints.users}/reset-password/init`, {
            method: 'POST',
            bodyReq: { email },
        });

        if (response.error) throw response;

        return response;
    } catch (err: any) {
        throw new ServerError(err);
    }
};
export const putCreateNewPasswordService = async (password: string, token: string) => {
    try {
        let response = await customFetch(`${endpoints.users}/reset-password/finish`, {
            method: 'PUT',
            bodyReq: {
                resetKey: token,
                password: password,
                confirmPassword: password,
            },
        });

        return response;
    } catch (err: any) {
        throw new ServerError(err);
    }
};

export const patchUserService = async (body: {}) => {
    const token = getUserToken();
    try {
        let response = await customFetch(`${endpoints.user}/me`, {
            method: 'PATCH',
            bodyReq: body,
            token,
        });

        return response;
    } catch (err: any) {
        throw new ServerError(err);
    }
};

export const getPhoneCode = async () => {
    const token = getUserToken();
    try {
        let response = await customFetch(`${endpoints.users}/get-phone-code`, {
            method: 'GET',
            token,
        });

        return response;
    } catch (err: any) {
        throw new ServerError(err);
    }
};

export const postVerifyPhoneService = async (verificationCode: string) => {
    const token = getUserToken();
    try {
        let response = await customFetch(`${endpoints.users}/verify-phone`, {
            method: 'POST',
            bodyReq: {
                verificationCode,
            },
            token,
        });

        return response;
    } catch (err: any) {
        throw new ServerError(err);
    }
};

export const uploadImageService = async (file: File) => {
    return new Promise((resolve, reject) => {
        const compressImage = (file: File, quality: number, attempt: number = 1) => {
            new Compressor(file, {
                quality: quality,
                success: (compressedFile) => {
                    if (compressedFile.size > 1000000) {
                        if (attempt < 5) { // Intenta comprimir 5 veces
                            compressImage(file, quality - 0.1, attempt + 1);
                        } else {
                            reject({ message: 'Error: Image is too big even after compression.' });
                        }
                    } else {
                        sendImage(compressedFile);
                    }
                },
                error: () => {
                    reject({ message: 'Error: Image compression failed.' });
                }
            });
        };

        const sendImage = (compressedFile: any) => {
            var data = new FormData();
            data.append(`files[]`, compressedFile);
            var xhr = new XMLHttpRequest();
            xhr.addEventListener('readystatechange', function () {
                if (this.readyState === 4) {
                    resolve(xhr.responseText);
                }
            });
            xhr.addEventListener('error', function () {
                reject(xhr.responseText);
            });
            xhr.open('POST', endpoints.media);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getUserToken());
            xhr.send(data);
        };

        compressImage(file, 0.6);
    });
};


export const deleteUserService = async () => {
    const token = getUserToken();
    try {
        let response = await customFetch(`${endpoints.globalUser}/me`, {
            method: 'DELETE',
            token
        });

        return response;
    } catch (err: any) {
        throw new ServerError(err);
    }
}