import * as React from "react"

const ThumbsUpIcon = (props) => (
    <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M4 13h6v13H4a1 1 0 0 1-1-1V14a1 1 0 0 1 1-1v0ZM10 13l5-10a4 4 0 0 1 4 4v3h7.734a2 2 0 0 1 1.985 2.248l-1.5 12A2 2 0 0 1 25.234 26H10"
            stroke={props.color || "#A1A1A5"}
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default ThumbsUpIcon