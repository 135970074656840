import Button from 'components/elements/Button/Button';
import Header from 'components/elements/Header/Header';
import InputCode from 'components/elements/InputCode/InputCode';
import { Form, Formik } from 'formik';
import AuthLayout from 'layouts/AuthLayout/AuthLayout';
import React, { useEffect, useRef } from 'react';
import Logo from 'components/elements/Logo/Logo';
import * as yup from 'yup';
import routes from 'constants/routes';
import './smsConfirmation.scss';

const SmsCodeComponent: React.FC<any> = (props) => {
    const { error, submit, loading, location, history, resendCode } = props;
    const codeArray = ['number1', 'number2', 'number3', 'number4'];
    const phoneNumber = location.state?.number;
    const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

    const handleChange = (index: any, e: any) => {
        if (e.target.value !== '') {
            inputRefs.current[index + 1]?.focus();
        }
    };

    useEffect(() => {
        if (!!inputRefs) inputRefs.current[0]?.focus();
    }, []);

    return (
        <div className="sms-code-component">
            <Logo />
            <Header goBack={() => history.push(routes.PHONE_VERIFICATION)} title="SMS Code" />
            <AuthLayout
                title="SMS Code"
                subtitle={`Enter verification code from SMS sent to ${phoneNumber}`}
                icon={require('../../../assets/images/sms-icon.png')}
            >
                <div className="sms-code-form">
                    <Formik
                        initialValues={{
                            number1: '',
                            number2: '',
                            number3: '',
                            number4: '',
                        }}
                        isInitialValid={false}
                        validationSchema={yup.object().shape({
                            number1: yup.string().required('Required'),
                            number2: yup.string().required('Required'),
                            number3: yup.string().required('Required'),
                            number4: yup.string().required('Required'),
                        })}
                        onSubmit={submit}
                    >
                        {(formik) => (
                            <>
                                <Form>
                                    <div className="inputs-wrapper">
                                        {codeArray.map((number, index) => (
                                            <InputCode
                                                key={index}
                                                id={number}
                                                formik={formik}
                                                autoFocus={index === 0 && true}
                                                onHandleChange={(e: any) => handleChange(index, e)}
                                                ref={(el: any) => (inputRefs.current[index] = el)}
                                            />
                                        ))}
                                    </div>
                                    {error ? <div className="error-message-global">{error}</div> : null}

                                    <p className="text-link">
                                        Didn’t receive any code? <span onClick={resendCode}>Resend code</span>
                                    </p>

                                    <Button
                                        className="sms-code-button"
                                        loading={loading}
                                        type="button"
                                        disabled={!formik.isValid}
                                        onClick={formik.handleSubmit}
                                    >
                                        Next
                                    </Button>
                                </Form>
                            </>
                        )}
                    </Formik>
                </div>
            </AuthLayout>
        </div>
    );
};

export default SmsCodeComponent;
