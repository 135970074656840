import routes from 'constants/routes';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getObstaclesService } from 'services/obstacles';
import { patchUserService } from 'services/user';
import useUser from 'stores/user';
import EditObstaclesComponent from "../components/EditObstaclesComponent"

export interface Obstacle {
    idObstacle: string,
    nameObstacle: string
}

const EditObstacles: React.FC<any> = () => {
    const history = useHistory<any>();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const { user, updateUser } = useUser()
    const [obstacles, setObstacles] = useState<any[]>([]);
    const [updated, setUpdated] = useState(false)

    const getObstacles = async () => {
        try {
            const data = await getObstaclesService();
            setObstacles(data);
        } catch (error: any) {
            setError(error.message);
        }
    }

    useEffect(() => {
        getObstacles();
    }, []);

    const submit = async (values: any) => {
        setLoading(true);

        const body = {
            userFeatures: {
                obstacles: values.obstacles.map((value: Obstacle) => value.idObstacle)
            }
        }

        try {
            await patchUserService(body);
            updateUser(values)
            setUpdated(true)
        } catch (error: any) {
            setError(error.message)
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (updated) {
            setUpdated(false)
            if (history.location.state?.data === 'profile') {
                return history.push(routes.PROFILE);
            }
            return history.push(routes.PROFILE_CONFIRMATION);
        }
    }, [updated, history])

    return <EditObstaclesComponent
        submit={submit}
        error={error}
        loading={loading}
        history={history}
        obstacles={obstacles}
        user={user}
    />
}

export default EditObstacles;