import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import _ from 'lodash';
import useUser from 'stores/user';
import routes from 'constants/routes';

const PrivateRoute = ({ component: Component, role, ...rest }) => {
    const user = useUser(state => state.user);
    const isLogged = !_.isEmpty(user);
    const userRole = user && user.role;
    const roleMatch = role ? (userRole === role) : true;

    const createProfileRoutes = [routes.PHONE_VERIFICATION, routes.SMS_CONFIRMATION, routes.WELCOME, routes.COMUNITY_RULES, routes.CREATE_PROFILE_STEP_1, routes.CREATE_PROFILE_STEP_2, routes.CREATE_PROFILE_STEP_3, routes.CREATE_PROFILE_STEP_4, routes.CREATE_PROFILE_STEP_5, routes.CREATE_PROFILE_STEP_6, routes.CREATE_PROFILE_STEP_7, routes.CREATE_PROFILE_STEP_8, routes.CREATE_PROFILE_STEP_9]

    const redirectIfProfileIsNotCreated = (props) => {
        if (createProfileRoutes.includes(window.location.pathname)) return <Component {...props} />;
        if (!user.telephone) return <Redirect to={routes.PHONE_VERIFICATION} />;
        if ((!user.firstName || !user.lastName)) return <Redirect to={routes.WELCOME} />;
        if (user.userSettings?.matchesByLocation !== true && user.userSettings?.matchesByLocation !== false) return <Redirect to={routes.CREATE_PROFILE_STEP_2} />;
        if ((!user.passions || user.passions.length < 3)) return <Redirect to={routes.CREATE_PROFILE_STEP_3} />;
        if ((!user.obstacles || user.obstacles.length < 1)) return <Redirect to={routes.CREATE_PROFILE_STEP_4} />;
        if (!user.messagesFrecuency) return <Redirect to={routes.CREATE_PROFILE_STEP_5} />;
        if (!user.availability) return <Redirect to={routes.CREATE_PROFILE_STEP_6} />;
        return <Component {...props} />;
    }

    return (
        <Route {...rest} render={props => (
            isLogged && roleMatch
                ? redirectIfProfileIsNotCreated(props)
                : <Redirect to={routes.LOGIN} />
        )} />
    );
};



export default PrivateRoute;