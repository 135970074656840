import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './MatchCardComponent.scss';
import ChatTeardropDots from 'components/icons/ChatTeardropDots';
import ClockIcon from 'components/icons/ClockIcon';
import User from 'models/User';

const MatchCardComponent: React.FC<any> = () => {
    return (
        <div className={`match-card skeleton`}>
            <div className="picture" />
            <div className={`match-content`}>
                <div className="name" />
                <div className="about" />

                <div className="name" />
                <div className="item" />
            </div>
        </div>
    );
};

export default MatchCardComponent;
