import React from 'react'
import Logo from '../Logo/Logo';
import './Header.scss'

export interface Props {
    goBack?: () => void;
    title: string;
    noBackgrounnd?: boolean;
    skipButton?: () => void;
    cancel?: () => void;
    save?: () => void;
    right?: any;
}

const Header: React.FC<Props> = (props) => {
    const { goBack, title, noBackgrounnd, skipButton, cancel, save, right } = props

    return (
        <div className={`header ${noBackgrounnd ? 'no-background' : ''}`}>
            {goBack && <button className="button-with-chevron" onClick={goBack}>
                <img src={require('../../../assets/images/chevron.png')} alt="back" />
                <span className="button-text">Back</span>
            </button>}
            {cancel && <button className="button-with-chevron" onClick={cancel}>
                <img src={require('../../../assets/images/chevron.png')} alt="back" />
                <span className="button-text">Cancel</span>
            </button>}
            {!goBack && !cancel && <div />}

            <span className='header-title'>{title}</span>

            {skipButton &&
                <button className="button-with-chevron reverse" onClick={skipButton}>
                    <img src={require('../../../assets/images/chevron.png')} alt="button" />
                    <span className="button-text">Skip</span>
                </button>
            }
            {save && <button className="button-with-chevron reverse" onClick={save}>
                <img src={require('../../../assets/images/chevron.png')} alt="button" />
                <span className="button-text">Save</span>
            </button>}
            {right && right}

            {!skipButton && !save && <div />}
        </div>
    )

}

export default Header;