import * as React from "react"

const ClockIcon = (props) => (
    <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M10 17.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Z"
            stroke={props.color || '#c6c6c8'}
            strokeWidth={1.5}
            strokeMiterlimit={10}
        />
        <path
            d="M10 5.625V10h4.375"
            stroke={props.color || '#c6c6c8'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default ClockIcon
