import React, { useState } from "react";
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';

import Input from "components/elements/Input/Input";
import { Formik, Form } from "formik";
import Button from "components/elements/Button/Button";
import * as yup from "yup";

import './FeedbackComponent.scss';
import MaterialInput from "components/elements/MaterialInput/materialInput";

const FeedbackComponent: React.FC<any> = props => {
    const { submit, loading } = props
    const [rate, setRate] = useState("");

    return (
        <DashboardLayout className="feedback-layout" logoWhite>
            <div className="feedback-component">
                <div className="feedback-head">
                    <h3 className="feedback-head__header">Help us improve our app!</h3>
                    <p className="feedback-head__subheader">This app is still under development. Let us know if you have any suggestions and we can help make this the best app in can be!</p>
                </div>
                <div className="feedback-form">
                    <Formik
                        initialValues={{ rate: "", comment: "" }}
                        isInitialValid={false}
                        onSubmit={(values) => submit(values)}
                        validationSchema={yup.object().shape({
                            rate: yup.string().required("Rate is a required field"),
                            comment: yup
                                .string()
                                .min(1)
                                .max(400)
                                .required("Comment is a required field"),
                        })}>
                        {(formik) => (
                            <Form>
                                <p className="feedback-form__heading">How do you generally feel about the app?</p>
                                <div className="feedback-form__rate">
                                    <div className={`feedback-form__rate__option ${rate === 'dislike' ? 'selected' : ''}`}
                                        onClick={() => {
                                            setRate('dislike')
                                            formik.setFieldValue('rate', 'DISLIKE')
                                        }}>
                                        <p>Dislike</p>
                                    </div>
                                    <div className={`feedback-form__rate__option ${rate === 'neutral' ? 'selected' : ''}`}
                                        onClick={() => {
                                            setRate('neutral')
                                            formik.setFieldValue('rate', 'NEUTRAL')
                                        }}>
                                        <p>Neutral</p>
                                    </div>
                                    <div className={`feedback-form__rate__option ${rate === 'like' ? 'selected' : ''}`}
                                        onClick={() => {
                                            setRate('like')
                                            formik.setFieldValue('rate', 'LIKE')
                                        }}>
                                        <p>Like</p>
                                    </div>
                                </div>


                                <p className="feedback-form__heading">What two things can make this better?</p>
                                <div className="feedback-form__comment">
                                    <MaterialInput
                                        id="comment"
                                        formik={formik}
                                        className="formControl-about"
                                        placeholder="Write here"
                                        type="textarea"
                                        label=""
                                        maxLength={400}
                                        variant='outlined'
                                        caption={`${formik.values["comment"].length}/400`}
                                    />
                                </div>
                                <div className="feedback-button_container">
                                    <Button
                                        loading={loading}
                                        type="button"
                                        disabled={!formik.isValid}
                                        onClick={formik.handleSubmit}>
                                        Send
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>

                </div>
            </div>
        </DashboardLayout>
    );
}

export default FeedbackComponent;
