import React from 'react';
import { useHistory } from 'react-router-dom';
import PrivacyPolicyComponent from '../components/PrivacyPolicyComponent';

const PrivacyPolicy: React.FC = () => {
    const history: any = useHistory();

    const goBack = () => {
        return history.push(history.location.state.previousPathname, { ...history.location.state })
    }

    return <PrivacyPolicyComponent goBack={goBack} />
}

export default PrivacyPolicy;