import React, { useEffect, useRef, useState } from 'react';
import { getChatsService, getUnreadMessagesCount, listenToRoomsService } from 'services/chats';
import useUser from 'stores/user';
import { Room } from 'types/Room';
import MessagesComponent from '../components/MessagesComponent';
import _ from 'lodash';
import { getUserToken } from 'services/utils';
import { getUserIdService } from 'services/user';

const Messages: React.FC = () => {
    const { user, setUser } = useUser();
    const [chats, setChats] = useState<any>();
    const [loading, setLoading] = useState(true);
    const chatsRef = useRef<any>({});

    const getChats = async () => {
        const data = await getChatsService();
        chatsRef.current = data.chats;
        const firebaseIdsArray = Object.values(data.chats).map((chat: any) => chat.firebaseId);
        setChats(Object.values(chatsRef.current));
        listenToRoomsService(firebaseIdsArray, chatsCallback);
        if (_.isEmpty(data.chats)) setLoading(false);
    };

    const chatsCallback = async (room: Room) => {
        let unseenMessages = 0;
        if (user.idUser) unseenMessages = await getUnreadMessagesCount(room.id, room[user.idUser]);
        const chatKey = Object.keys(chatsRef.current).find((key) => chatsRef.current[key].firebaseId === room.id);
        if (chatKey) {
            chatsRef.current[chatKey] = {
                ...chatsRef.current[chatKey],
                ...room,
                unseenMessages,
            };
        }
        setChats(Object.values(chatsRef.current));
        setLoading(false);
    };

    const verifyIfUserIsBloqued = async () => {
        const token = getUserToken();
        const resp = await getUserIdService(token);

        if (resp.user.isBlocked) {
            setUser({});
            localStorage.clear();
            return true;
        }

        return false;
    };

    useEffect(() => {
        verifyIfUserIsBloqued();
        getChats();
        const timeout = setTimeout(() => {
            setLoading(false);
        }, 1500);
        return () => clearTimeout(timeout);
    }, []);

    return <MessagesComponent chats={chats} user={user} loading={loading} />;
};

export default Messages;
