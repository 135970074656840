import routes from 'constants/routes';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { patchUserSettingService } from 'services/user-settings';
import useUser from 'stores/user';
import CreateProfileStep2Component from '../components/CreateProfileStep2Component';
import * as Mixpanel from 'config/mixpanel';

const CreateProfileStep2: React.FC<any> = () => {
    const history = useHistory<any>();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const { user, updateUser } = useUser();
    const [updated, setUpdated] = useState(false);

    const submit = async ({ matchesByLocation }: { matchesByLocation: boolean }) => {
        setLoading(true);

        try {
            user.idUser && (await patchUserSettingService(user.idUser, { matchesByLocation }));
            updateUser({ userSettings: { ...user.userSettings, matchesByLocation } });

            Mixpanel.actions.sendEvent(Mixpanel.EVENT_TYPES.ONBOARDING_PRIORITIZE_LOCATION, {
                'User ID': user.idUser,
                'Sign up date': undefined,
                'Enabled Push Notifications ': user.userSettings ? user.userSettings.allowNotifications : false,
                'Email address': user.email,
                'Phone Number': user.telephone,
                $name: `${user.firstName} ${user.lastName}`,
                'Prioritize Matches based on Location?': matchesByLocation,
                Interests: user.passions,
                Troubles: user.obstacles,
                'Message Frequency Preference ': user.messagesFrecuency,
                'Message Timing Preference ': user.availability,
                'Weightloss Goal': user.weightGoal,
                '3 Important Things': user.about,
                '#Matches': undefined,
                '#Messages Sent': undefined,
                'First Login Date': undefined,
                'Last Login Date ': undefined,
            });

            setUpdated(true);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (updated) {
            setUpdated(false);
            return history.push(routes.CREATE_PROFILE_STEP_3);
        }
    }, [updated, history]);

    return (
        <CreateProfileStep2Component submit={submit} error={error} loading={loading} history={history} user={user} />
    );
};

export default CreateProfileStep2;
