import * as React from "react"

const ChatTeardropDots = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.313 16.874H3.723a.6.6 0 0 1-.599-.599V9.687a7.188 7.188 0 0 1 7.188-7.188h0A7.187 7.187 0 0 1 17.5 9.687v0a7.188 7.188 0 0 1-7.188 7.187Z"
      stroke={props.color || '#c6c6c8'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.313 10.938a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.874ZM6.563 10.938a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.874ZM14.063 10.938a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.874Z"
      fill={props.color || '#c6c6c8'}
    />
  </svg>
)

export default ChatTeardropDots
