import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { getRecoverPasswordLink } from 'services/user';
import ForgotPasswordComponent from '../components/ForgotPasswordComponent'

const ForgotPassword: React.FC<any> = () => {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const history = useHistory<any>();

    const submit = async (values: { email: string }) => {
        try {
            setLoading(true);
            setSuccess(false);
            await getRecoverPasswordLink(values.email);
            setSuccess(true);
        }
        catch (e: any) {
            console.log('error ', e);
            setError(e.message);
        }
        finally {
            setLoading(false);
        }
    }

    return <ForgotPasswordComponent
        submit={submit}
        history={history}
        loading={loading}
        error={error}
        success={success}
    />;
}

export default ForgotPassword;