import { Checkbox } from '@material-ui/core';
import React from 'react';
import './MaterialCheckBox.scss';

export const MaterialCheckBox = (props: any) => {
    const { value, onChange, disabled, className } = props;

    return (
        <div className={`check-box-wrapper${className ? ` ${className}` : ''}`}>
            <Checkbox
                checked={value}
                onChange={onChange}
                color="primary"
                disabled={disabled}
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
        </div>
    )
}
export default MaterialCheckBox;