import User from 'models/User';
import React from 'react';
import MatchCardComponent from '../components/MatchCardComponent';

interface Props {
    info: User;
    onClick?: () => void;
    onLike?: () => any;
    onDislike?: () => any;
    details?: boolean;
}

const MatchCard: React.FC<Props> = (props) => {
    return (
        <MatchCardComponent
            info={props.info}
            onClick={props.onClick}
            details={props.details}
            onLike={props.onLike}
            onDislike={props.onDislike}
        />)
}

export default MatchCard;