import routes from 'constants/routes';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { patchUserService, uploadImageService } from 'services/user';
import useUser from 'stores/user';
import * as Mixpanel from 'config/mixpanel';
import CreateProfileStep9Component from '../components/CreateProfileStep9Component';

const CreateProfileStep9: React.FC<any> = (props) => {
    const history = useHistory<any>();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState<any>(null);
    const { user, updateUser } = useUser();
    const [updated, setUpdated] = useState(false);

    const handleFile = (file: any) => {
        if (!!file) setFile({ file, preview: URL.createObjectURL(file) });
    };

    const removeFile = () => setFile(null);

    const skip = () => setUpdated(true);

    const submit = async () => {
        if (!file?.file) return setUpdated(true);

        setLoading(true);

        try {
            const imageLink: string | any = await uploadImageService(file.file);
            const body = {
                userFeatures: {
                    imgProfile: imageLink,
                },
            };
            await patchUserService(body);
            updateUser({ imgProfile: imageLink });

            Mixpanel.actions.sendEvent(Mixpanel.EVENT_TYPES.ONBOARDING_PROFILE_IMAGE, {
                'User ID': user.idUser,
                'Sign up date': undefined,
                'Enabled Push Notifications ': user.userSettings ? user.userSettings.allowNotifications : false,
                'Email address': user.email,
                'Phone Number': user.telephone,
                $name: `${user.firstName} ${user.lastName}`,
                'Prioritize Matches based on Location?': user.userSettings
                    ? user.userSettings.matchesByLocation
                    : false,
                Interests: user.passions,
                Troubles: user.obstacles,
                'Message Frequency Preference ': user.messagesFrecuency,
                'Message Timing Preference ': user.availability,
                'Weightloss Goal': user.weightGoal,
                '3 Important Things': user.about,
                '#Matches': undefined,
                '#Messages Sent': undefined,
                'First Login Date': undefined,
                'Last Login Date ': undefined,
            });

            setUpdated(true);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (user.imgProfile) setFile((file: any) => ({ ...file, preview: user.imgProfile }));
    }, [user]);

    useEffect(() => {
        if (updated) {
            setUpdated(false);
            return history.push(routes.PROFILE_CONFIRMATION);
        }
    }, [updated, history]);

    return (
        <CreateProfileStep9Component
            {...props}
            submit={submit}
            error={error}
            loading={loading}
            history={history}
            file={file}
            handleFile={handleFile}
            removeFile={removeFile}
            skip={skip}
        />
    );
};

export default CreateProfileStep9;
