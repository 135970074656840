import routes from 'constants/routes';
import React, { useState } from 'react'
import { putCreateNewPasswordService } from 'services/user';
import NewPasswordComponent from '../components/NewPasswordComponent';

const NewPassword: React.FC<any> = (props) => {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const { history, match: { params: { token } } } = props;

    const submit = async (values: { password: string }) => {
        try {
            setLoading(true);
            setSuccess(false);
            await putCreateNewPasswordService(values.password, token);
            setSuccess(true);
            history.push(routes.LOGIN)
        }
        catch (e: any) {
            console.log('error ', e);
            setError(e.message);
        }
        finally {
            setLoading(false);
        }
    }

    return <NewPasswordComponent
        submit={submit}
        history={history}
        loading={loading}
        error={error}
        success={success}
    />;
}

export default NewPassword;