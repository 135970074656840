import * as React from "react"

const MoonStarsIcon = (props) => (
    <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M30 10h-2V8a1 1 0 0 0-2 0v2h-2a1 1 0 0 0 0 2h2v2a1 1 0 0 0 2 0v-2h2a1 1 0 0 0 0-2ZM19 6h1v1a1 1 0 0 0 2 0V6h1a1 1 0 1 0 0-2h-1V3a1 1 0 0 0-2 0v1h-1a1 1 0 1 0 0 2ZM27.057 18.081a.998.998 0 0 0-.27.044 10.504 10.504 0 0 1-12.91-12.92 1 1 0 0 0-1.246-1.246 12.502 12.502 0 1 0 15.411 15.403 1 1 0 0 0-.985-1.28Z"
            fill={props.white ? "#00525c" : '#FFFFFF'}
        />
    </svg>
)

export default MoonStarsIcon
