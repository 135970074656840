import React from 'react';
import ProfileCreationLayout from 'layouts/ProfileCreationLayout/ProfileCreationLayout';
import './CreateProfileStep1Component.scss';
import Button from 'components/elements/Button/Button';
import { Formik, Form } from "formik";
import * as yup from "yup";
import checkIfSubmitButtonisDisabled from 'utils/checkIfSubmitButtonisDisabled';
import MaterialInput from 'components/elements/MaterialInput/materialInput';
import useFormikSubmitOutside from 'hooks/useFormikSubmitOutside';
import routes from 'constants/routes';

const CreateProfileStep1Component: React.FC<any> = (props) => {
    const { submit, error, loading, history, user } = props;
    const { formikRef, submitForm } = useFormikSubmitOutside();

    return (
        <ProfileCreationLayout
            percentage={10}
            title="What’s your name?"
            goBack={() => history.push(routes.WELCOME)}
            submitButton={() =>
                <Button
                    loading={loading}
                    type="button"
                    disabled={checkIfSubmitButtonisDisabled({ values: formikRef.current?.values, errors: formikRef.current?.errors }, ['firstName', 'lastName'])}
                    onClick={submitForm}
                >
                    Continue
                </Button>}
        >
            <div className="create-profile-form-step-1 overflow-container">
                <Formik
                    innerRef={formikRef}
                    initialValues={{ firstName: user.firstName, lastName: user.lastName }}
                    validationSchema={yup.object().shape({
                        firstName: yup.string().required("First name is required"),
                        lastName: yup.string().required("Last name is required")
                    })}
                    onSubmit={submit}>
                    {(formik) => (
                        <Form>
                            <MaterialInput
                                id="firstName"
                                formik={formik}
                                className="formControl-firstname"
                                placeholder="First name"
                                label="First name"
                            />
                            <MaterialInput
                                id="lastName"
                                formik={formik}
                                className="formControl"
                                placeholder="Last name"
                                label="Last name"
                            />
                            {error
                                ? <div className="error-message-global">{error}</div>
                                : null
                            }
                        </Form>
                    )}
                </Formik>
            </div>
        </ProfileCreationLayout >);
}

export default CreateProfileStep1Component;