import React, { useRef } from 'react';
import ProfileCreationLayout from 'layouts/ProfileCreationLayout/ProfileCreationLayout';
import './CreateProfileStep9Component.scss';
import Button from 'components/elements/Button/Button';
import { Formik, Form } from "formik";
import routes from 'constants/routes';
import GalleryIcon from 'components/icons/GalleryIcon';
import useFormikSubmitOutside from 'hooks/useFormikSubmitOutside';

const CreateProfileStep9Component: React.FC<any> = (props) => {
    const { submit, error, loading, history, file, handleFile, removeFile, skip } = props;

    const { formikRef, submitForm } = useFormikSubmitOutside();

    let fileInputRef = useRef<any>(null);

    const handleClick = () => {
        fileInputRef.current.click();
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const fileUploaded = e.target.files[0];
            handleFile(fileUploaded);
        }
    };

    return (
        <ProfileCreationLayout percentage={90}
            title="Profile image"
            goBack={() => history.push(routes.CREATE_PROFILE_STEP_8)}
            skip={skip}
            submitButton={() =>
                <Button
                    loading={loading}
                    type="button"
                    onClick={submitForm}>
                    Continue
                </Button>
            }
        >
            <div className="create-profile-form-step-9 overflow-container">
                <Formik
                    innerRef={formikRef}
                    initialValues={{}}
                    onSubmit={submit}>
                    {() => (
                        <Form>
                            {file?.preview
                                ?
                                <div className='card-file-container'>
                                    <img className="card-image" src={file.preview}
                                        alt="uploaded"
                                    />
                                    <img className="card-close"
                                        src={require('../../../assets/images/close-icon.png')}
                                        alt="close"
                                        onClick={removeFile}
                                    />
                                </div>
                                :
                                <>
                                    <div className="create-profile-form-card"
                                        onClick={handleClick}
                                    >
                                        <div className="card-icon">
                                            <GalleryIcon />
                                        </div>
                                        <p className="body1">From gallery</p>
                                    </div>
                                    <input ref={fileInputRef}
                                        type="file"
                                        multiple
                                        accept="image/*"
                                        onChange={handleChange}
                                        style={{ display: 'none' }}
                                    />
                                </>
                            }
                            {error
                                ? <div className="error-message-global">{error}</div>
                                : null
                            }
                        </Form>
                    )}
                </Formik>
            </div>
        </ProfileCreationLayout >);
}

export default CreateProfileStep9Component;