import React from 'react';
import { useHistory } from 'react-router-dom';
import useUser from 'stores/user';
import ProfileConfirmationComponent from "../components/ProfileConfirmationComponent"

const ProfileConfirmation: React.FC<any> = (props) => {
    const history = useHistory<any>();
    const user = useUser(state => state.user)

    return <ProfileConfirmationComponent
        {...props}
        history={history}
        user={user}
    />
}

export default ProfileConfirmation;