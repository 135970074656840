import React, { useEffect, useRef, useState } from "react";
import ProfileCreationLayout from "layouts/ProfileCreationLayout/ProfileCreationLayout";
import "./CreateProfileStep4Component.scss";
import Button from "components/elements/Button/Button";
import { Formik, Form } from "formik";
import routes from "constants/routes";
import MaterialCheckbox from "../../elements/Selects/MaterialCheckBox/MaterialCheckBox"
import { Obstacle } from "../containers/CreateProfileStep4";
import useFormikSubmitOutside from "hooks/useFormikSubmitOutside";



const MIN_SELECTED = 1;
const MAX_SELECTED = 7;

const CreateProfileStep4Component: React.FC<any> = (props) => {
    const { submit, error, loading, history, obstacles, user } = props;
    const [obstaclesSelected, setObstaclesSelected] = useState<any[]>(user.obstacles);
    const { formikRef, submitForm } = useFormikSubmitOutside();
    const [showArrowDown, setShowArrowDown] = useState(true)

    const isObstacleSelected = (obstacle: Obstacle) => {
        if (obstaclesSelected.find(({ idObstacle }) => idObstacle === obstacle.idObstacle)) {
            return true;
        }
        return false;
    }

    const handleSelection = (obstacle: Obstacle, setValue: (name: string, value: Obstacle[]) => void) => {
        let newObstacles = [];
        if (isObstacleSelected(obstacle)) {
            newObstacles = obstaclesSelected.filter(({ idObstacle }) => idObstacle !== obstacle.idObstacle);
            setValue("obstacles", newObstacles);
            return setObstaclesSelected(newObstacles);
        }

        if (obstaclesSelected.length === MAX_SELECTED) return;

        newObstacles = [...obstaclesSelected, obstacle]
        setValue("obstacles", newObstacles);
        return setObstaclesSelected(newObstacles);
    }

    const scrollRef = useRef<any>({})
    useEffect(() => {
        window.addEventListener('scroll', trackScrolling);
        return () => window.removeEventListener('scroll', trackScrolling);
    }, []);

    const trackScrolling = () => {
        if (scrollRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
            if ((scrollTop + clientHeight + 40 >= scrollHeight) && showArrowDown) {
                setShowArrowDown(false)
            }
            else if ((scrollTop + clientHeight + 40 < scrollHeight) && !showArrowDown) setShowArrowDown(true)
        }
    };
    return (
        <ProfileCreationLayout percentage={40}
            title="What are you having trouble with?"
            subtitle={`Choose at least ${MIN_SELECTED}, up to ${MAX_SELECTED}`}
            goBack={() => history.push(routes.CREATE_PROFILE_STEP_3)}
            submitButton={() =>
                <Button
                    loading={loading}
                    type="button"
                    disabled={obstaclesSelected.length < MIN_SELECTED}
                    onClick={submitForm}>
                    Continue
                </Button>}
            arrowDown={showArrowDown}
        >
            <div className="create-profile-form-step-4 overflow-container" onScroll={trackScrolling} ref={scrollRef}>
                <Formik
                    innerRef={formikRef}
                    initialValues={{ obstacles: user.obstacles }}
                    onSubmit={submit}>
                    {(formik) => (
                        <Form>
                            <div className="create-profile-form-grid">
                                {obstacles.map((obstacle: Obstacle) => (
                                    <div className={`create-profile-form-card${isObstacleSelected(obstacle) ? ' selected' : ''}`}
                                        key={obstacle.idObstacle}
                                        onClick={() => handleSelection(obstacle, formik.setFieldValue)}>
                                        <p className="body1">{obstacle.nameObstacle}</p>
                                        <MaterialCheckbox value={isObstacleSelected(obstacle)} className="secondary" />
                                    </div>
                                ))}
                            </div>
                            {error
                                ? <div className="error-message-global">{error}</div>
                                : null
                            }
                        </Form>
                    )}
                </Formik>
            </div>
        </ProfileCreationLayout >);
}

export default CreateProfileStep4Component;