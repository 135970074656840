import * as React from "react"

const ThumbsUpFilledIcon = (props) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.983 10.014A3.002 3.002 0 0 0 26.734 9H20V7a5.006 5.006 0 0 0-5-5 1 1 0 0 0-.894.553L9.382 12H4a2.002 2.002 0 0 0-2 2v11a2.002 2.002 0 0 0 2 2h21.234a3.005 3.005 0 0 0 2.977-2.628l1.5-12a3.002 3.002 0 0 0-.728-2.357ZM4 14h5v11H4V14Z"
      fill={props.color || "#00525C"}
    />
  </svg>
)

export default ThumbsUpFilledIcon
