import CalendarBlankIcon from 'components/icons/CalendarBlankIcon';
import CalendarCheckIcon from 'components/icons/CalendarCheckIcon';
import ChatsTeardropIcon from 'components/icons/ChatsTeardropIcon';
import ChatTearDropIcon from 'components/icons/ChatTearDropIcon';
import routes from 'constants/routes';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { patchUserService } from 'services/user';
import useUser from 'stores/user';
import * as Mixpanel from 'config/mixpanel';

import CreateProfileStep5Component from '../components/CreateProfileStep5Component';

export interface Frequency {
    name: string;
    Icon: (white: { white: boolean }) => JSX.Element | null;
}
const FREQUENCY_OPTIONS: Frequency[] = [
    { name: 'Few times a day', Icon: (white) => <ChatsTeardropIcon white={white} /> },
    { name: 'Once a day', Icon: (white) => <ChatTearDropIcon white={white} /> },
    { name: 'Few times a week', Icon: (white) => <CalendarCheckIcon white={white} /> },
    { name: 'Once a week', Icon: (white) => <CalendarBlankIcon white={white} /> },
];

const CreateProfileStep5: React.FC<any> = () => {
    const history = useHistory<any>();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const { user, updateUser } = useUser();
    const [updated, setUpdated] = useState(false);

    const submit = async (values: { frequency: Frequency }) => {
        setLoading(true);

        const body: { userFeatures: { messagesFrecuency: string } } = {
            userFeatures: {
                messagesFrecuency: values.frequency.name,
            },
        };

        try {
            await patchUserService(body);
            updateUser({ messagesFrecuency: values.frequency.name });

            Mixpanel.actions.sendEvent(Mixpanel.EVENT_TYPES.ONBOARDING_MESSAGE_FREQUENCY_PREFERENCE, {
                'User ID': user.idUser,
                'Sign up date': undefined,
                'Enabled Push Notifications ': user.userSettings ? user.userSettings.allowNotifications : false,
                'Email address': user.email,
                'Phone Number': user.telephone,
                $name: `${user.firstName} ${user.lastName}`,
                'Prioritize Matches based on Location?': user.userSettings
                    ? user.userSettings.matchesByLocation
                    : false,
                Interests: user.passions,
                Troubles: user.obstacles,
                'Message Frequency Preference ': values.frequency.name,
                'Message Timing Preference ': user.availability,
                'Weightloss Goal': user.weightGoal,
                '3 Important Things': user.about,
                '#Matches': undefined,
                '#Messages Sent': undefined,
                'First Login Date': undefined,
                'Last Login Date ': undefined,
            });

            setUpdated(true);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (updated) {
            setUpdated(false);
            return history.push(routes.CREATE_PROFILE_STEP_6);
        }
    }, [updated, history]);

    return (
        <CreateProfileStep5Component
            submit={submit}
            error={error}
            loading={loading}
            history={history}
            frequencyOptions={FREQUENCY_OPTIONS}
            user={user}
        />
    );
};

export default CreateProfileStep5;
