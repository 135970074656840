import { ServerError } from './errorHandling';
import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken } from "./utils";

export const feedbackService = async (values: { opinion: string, suggestions: string }) => {
    const token = getUserToken()

    try {
        let response = await customFetch(`${endpoints.feedbacks}/me`, {
            method: "POST",
            bodyReq: values,
            token: token
        });

        return { response }

    } catch (err) {
        throw new ServerError(err);
    }
};

export const putFeedbackService = async (values: { opinion: string, suggestions: string }) => {
    const token = getUserToken()

    try {
        let response = await customFetch(`${endpoints.feedbacks}/me`, {
            method: "PUT",
            bodyReq: values,
            token: token
        });

        return response;
    } catch (err: any) {
        throw new ServerError(err);
    }
};

export const getUserFeedback = async () => {
    const token = getUserToken()
    try {
        let response = await customFetch(`${endpoints.feedbacks}/me`, {
            method: "GET",
            token,
        });

        return response;

    } catch (err: any) {
        if(err.statusCode === 404)
            return 404;
        throw new ServerError(err);
    }
};